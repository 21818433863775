@import url(../libs/fullpage.js/dist/fullpage.min.css);

@import url(../libs/owl.carousel/dist/assets/owl.carousel.css);

@import url(../libs/fancybox/dist/jquery.fancybox.min.css);

@import url(../libs/perfect-scrollbar/css/perfect-scrollbar.css);

@font-face {
	font-family: "ClearSans";
	font-weight: 100;
	font-style: normal;
	src: url("../fonts/clear_sans/ClearSans-Regular.eot");
	src: url("../fonts/clear_sans/ClearSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/clear_sans/ClearSans-Regular.woff") format("woff"), url("../fonts/clear_sans/ClearSans-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "ClearSans-Bold";
	font-weight: bold;
	font-style: normal;
	src: url("../fonts/clear_sans/ClearSans-Bold.eot");
	src: url("../fonts/clear_sans/ClearSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/clear_sans/ClearSans-Bold.woff") format("woff"), url("../fonts/clear_sans/ClearSans-Bold.ttf") format("truetype");
}

/*!
 * Hover.css (http://ianlunn.github.io/Hover/)
 * Version: 2.3.1
 * Author: Ian Lunn @IanLunn
 * Author URL: http://ianlunn.co.uk/
 * Github: https://github.com/IanLunn/Hover

 * Hover.css Copyright Ian Lunn 2017. Generated with Sass.
 */

/* 2D TRANSITIONS */

/* Grow */

.hvr-grow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

/* Shrink */

.hvr-shrink {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-shrink:hover,
.hvr-shrink:focus,
.hvr-shrink:active {
	-webkit-transform: scale(0.9);
	-ms-transform: scale(0.9);
	transform: scale(0.9);
}

/* Pulse */

.hvr-pulse {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-pulse:hover,
.hvr-pulse:focus,
.hvr-pulse:active {
	-webkit-animation-name: hvr-pulse;
	animation-name: hvr-pulse;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

/* Pulse Grow */

.hvr-pulse-grow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-pulse-grow:hover,
.hvr-pulse-grow:focus,
.hvr-pulse-grow:active {
	-webkit-animation-name: hvr-pulse-grow;
	animation-name: hvr-pulse-grow;
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	animation-direction: alternate;
}

/* Pulse Shrink */

.hvr-pulse-shrink {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-pulse-shrink:hover,
.hvr-pulse-shrink:focus,
.hvr-pulse-shrink:active {
	-webkit-animation-name: hvr-pulse-shrink;
	animation-name: hvr-pulse-shrink;
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	animation-direction: alternate;
}

/* Push */

.hvr-push {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-push:hover,
.hvr-push:focus,
.hvr-push:active {
	-webkit-animation-name: hvr-push;
	animation-name: hvr-push;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Pop */

.hvr-pop {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-pop:hover,
.hvr-pop:focus,
.hvr-pop:active {
	-webkit-animation-name: hvr-pop;
	animation-name: hvr-pop;
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Bounce In */

.hvr-bounce-in {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}

.hvr-bounce-in:hover,
.hvr-bounce-in:focus,
.hvr-bounce-in:active {
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
	transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Bounce Out */

.hvr-bounce-out {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}

.hvr-bounce-out:hover,
.hvr-bounce-out:focus,
.hvr-bounce-out:active {
	-webkit-transform: scale(0.8);
	-ms-transform: scale(0.8);
	transform: scale(0.8);
	-webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
	transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Rotate */

.hvr-rotate {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-rotate:hover,
.hvr-rotate:focus,
.hvr-rotate:active {
	-webkit-transform: rotate(4deg);
	-ms-transform: rotate(4deg);
	transform: rotate(4deg);
}

/* Grow Rotate */

.hvr-grow-rotate {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-grow-rotate:hover,
.hvr-grow-rotate:focus,
.hvr-grow-rotate:active {
	-webkit-transform: scale(1.1) rotate(4deg);
	-ms-transform: scale(1.1) rotate(4deg);
	transform: scale(1.1) rotate(4deg);
}

/* Float */

.hvr-float {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-float:hover,
.hvr-float:focus,
.hvr-float:active {
	-webkit-transform: translateY(-8px);
	-ms-transform: translateY(-8px);
	transform: translateY(-8px);
}

/* Sink */

.hvr-sink {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-sink:hover,
.hvr-sink:focus,
.hvr-sink:active {
	-webkit-transform: translateY(8px);
	-ms-transform: translateY(8px);
	transform: translateY(8px);
}

/* Bob */

.hvr-bob {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-bob:hover,
.hvr-bob:focus,
.hvr-bob:active {
	-webkit-animation-name: hvr-bob-float, hvr-bob;
	animation-name: hvr-bob-float, hvr-bob;
	-webkit-animation-duration: .3s, 1.5s;
	animation-duration: .3s, 1.5s;
	-webkit-animation-delay: 0s, .3s;
	animation-delay: 0s, .3s;
	-webkit-animation-timing-function: ease-out, ease-in-out;
	animation-timing-function: ease-out, ease-in-out;
	-webkit-animation-iteration-count: 1, infinite;
	animation-iteration-count: 1, infinite;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-direction: normal, alternate;
	animation-direction: normal, alternate;
}

/* Hang */

.hvr-hang {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-hang:hover,
.hvr-hang:focus,
.hvr-hang:active {
	-webkit-animation-name: hvr-hang-sink, hvr-hang;
	animation-name: hvr-hang-sink, hvr-hang;
	-webkit-animation-duration: .3s, 1.5s;
	animation-duration: .3s, 1.5s;
	-webkit-animation-delay: 0s, .3s;
	animation-delay: 0s, .3s;
	-webkit-animation-timing-function: ease-out, ease-in-out;
	animation-timing-function: ease-out, ease-in-out;
	-webkit-animation-iteration-count: 1, infinite;
	animation-iteration-count: 1, infinite;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-direction: normal, alternate;
	animation-direction: normal, alternate;
}

/* Skew */

.hvr-skew {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-skew:hover,
.hvr-skew:focus,
.hvr-skew:active {
	-webkit-transform: skew(-10deg);
	-ms-transform: skew(-10deg);
	transform: skew(-10deg);
}

/* Skew Forward */

.hvr-skew-forward {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transform-origin: 0 100%;
	-ms-transform-origin: 0 100%;
	transform-origin: 0 100%;
}

.hvr-skew-forward:hover,
.hvr-skew-forward:focus,
.hvr-skew-forward:active {
	-webkit-transform: skew(-10deg);
	-ms-transform: skew(-10deg);
	transform: skew(-10deg);
}

/* Skew Backward */

.hvr-skew-backward {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transform-origin: 0 100%;
	-ms-transform-origin: 0 100%;
	transform-origin: 0 100%;
}

.hvr-skew-backward:hover,
.hvr-skew-backward:focus,
.hvr-skew-backward:active {
	-webkit-transform: skew(10deg);
	-ms-transform: skew(10deg);
	transform: skew(10deg);
}

/* Wobble Vertical */

.hvr-wobble-vertical {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-wobble-vertical:hover,
.hvr-wobble-vertical:focus,
.hvr-wobble-vertical:active {
	-webkit-animation-name: hvr-wobble-vertical;
	animation-name: hvr-wobble-vertical;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Wobble Horizontal */

.hvr-wobble-horizontal {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-wobble-horizontal:hover,
.hvr-wobble-horizontal:focus,
.hvr-wobble-horizontal:active {
	-webkit-animation-name: hvr-wobble-horizontal;
	animation-name: hvr-wobble-horizontal;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Wobble To Bottom Right */

.hvr-wobble-to-bottom-right {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-wobble-to-bottom-right:hover,
.hvr-wobble-to-bottom-right:focus,
.hvr-wobble-to-bottom-right:active {
	-webkit-animation-name: hvr-wobble-to-bottom-right;
	animation-name: hvr-wobble-to-bottom-right;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Wobble To Top Right */

.hvr-wobble-to-top-right {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-wobble-to-top-right:hover,
.hvr-wobble-to-top-right:focus,
.hvr-wobble-to-top-right:active {
	-webkit-animation-name: hvr-wobble-to-top-right;
	animation-name: hvr-wobble-to-top-right;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Wobble Top */

.hvr-wobble-top {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transform-origin: 0 100%;
	-ms-transform-origin: 0 100%;
	transform-origin: 0 100%;
}

.hvr-wobble-top:hover,
.hvr-wobble-top:focus,
.hvr-wobble-top:active {
	-webkit-animation-name: hvr-wobble-top;
	animation-name: hvr-wobble-top;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Wobble Bottom */

.hvr-wobble-bottom {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transform-origin: 100% 0;
	-ms-transform-origin: 100% 0;
	transform-origin: 100% 0;
}

.hvr-wobble-bottom:hover,
.hvr-wobble-bottom:focus,
.hvr-wobble-bottom:active {
	-webkit-animation-name: hvr-wobble-bottom;
	animation-name: hvr-wobble-bottom;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Wobble Skew */

.hvr-wobble-skew {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-wobble-skew:hover,
.hvr-wobble-skew:focus,
.hvr-wobble-skew:active {
	-webkit-animation-name: hvr-wobble-skew;
	animation-name: hvr-wobble-skew;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Buzz */

.hvr-buzz {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-buzz:hover,
.hvr-buzz:focus,
.hvr-buzz:active {
	-webkit-animation-name: hvr-buzz;
	animation-name: hvr-buzz;
	-webkit-animation-duration: 0.15s;
	animation-duration: 0.15s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

/* Buzz Out */

.hvr-buzz-out {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-buzz-out:hover,
.hvr-buzz-out:focus,
.hvr-buzz-out:active {
	-webkit-animation-name: hvr-buzz-out;
	animation-name: hvr-buzz-out;
	-webkit-animation-duration: 0.75s;
	animation-duration: 0.75s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Forward */

.hvr-forward {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-forward:hover,
.hvr-forward:focus,
.hvr-forward:active {
	-webkit-transform: translateX(8px);
	-ms-transform: translateX(8px);
	transform: translateX(8px);
}

/* Backward */

.hvr-backward {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-backward:hover,
.hvr-backward:focus,
.hvr-backward:active {
	-webkit-transform: translateX(-8px);
	-ms-transform: translateX(-8px);
	transform: translateX(-8px);
}

/* BACKGROUND TRANSITIONS */

/* Fade */

.hvr-fade {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	overflow: hidden;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: color, background-color;
	transition-property: color, background-color;
}

.hvr-fade:hover,
.hvr-fade:focus,
.hvr-fade:active {
	background-color: #fff;
	color: white;
}

/* Back Pulse */

.hvr-back-pulse {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	overflow: hidden;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-property: color, background-color;
	transition-property: color, background-color;
}

.hvr-back-pulse:hover,
.hvr-back-pulse:focus,
.hvr-back-pulse:active {
	-webkit-animation-name: hvr-back-pulse;
	animation-name: hvr-back-pulse;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	background-color: #fff;
	background-color: #2098d1;
	color: white;
}

/* Sweep To Right */

.hvr-sweep-to-right {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-sweep-to-right:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 0 50%;
	-ms-transform-origin: 0 50%;
	transform-origin: 0 50%;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-sweep-to-right:hover,
.hvr-sweep-to-right:focus,
.hvr-sweep-to-right:active {
	color: white;
}

.hvr-sweep-to-right:hover:before,
.hvr-sweep-to-right:focus:before,
.hvr-sweep-to-right:active:before {
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1);
}

/* Sweep To Left */

.hvr-sweep-to-left {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-sweep-to-left:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 100% 50%;
	-ms-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-sweep-to-left:hover,
.hvr-sweep-to-left:focus,
.hvr-sweep-to-left:active {
	color: white;
}

.hvr-sweep-to-left:hover:before,
.hvr-sweep-to-left:focus:before,
.hvr-sweep-to-left:active:before {
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1);
}

/* Sweep To Bottom */

.hvr-sweep-to-bottom {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-sweep-to-bottom:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: 50% 0;
	-ms-transform-origin: 50% 0;
	transform-origin: 50% 0;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-sweep-to-bottom:hover,
.hvr-sweep-to-bottom:focus,
.hvr-sweep-to-bottom:active {
	color: white;
}

.hvr-sweep-to-bottom:hover:before,
.hvr-sweep-to-bottom:focus:before,
.hvr-sweep-to-bottom:active:before {
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	transform: scaleY(1);
}

/* Sweep To Top */

.hvr-sweep-to-top {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-sweep-to-top:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-sweep-to-top:hover,
.hvr-sweep-to-top:focus,
.hvr-sweep-to-top:active {
	color: white;
}

.hvr-sweep-to-top:hover:before,
.hvr-sweep-to-top:focus:before,
.hvr-sweep-to-top:active:before {
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	transform: scaleY(1);
}

/* Bounce To Right */

.hvr-bounce-to-right {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}

.hvr-bounce-to-right:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 0 50%;
	-ms-transform-origin: 0 50%;
	transform-origin: 0 50%;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-bounce-to-right:hover,
.hvr-bounce-to-right:focus,
.hvr-bounce-to-right:active {
	color: white;
}

.hvr-bounce-to-right:hover:before,
.hvr-bounce-to-right:focus:before,
.hvr-bounce-to-right:active:before {
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1);
	-webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Bounce To Left */

.hvr-bounce-to-left {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}

.hvr-bounce-to-left:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 100% 50%;
	-ms-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-bounce-to-left:hover,
.hvr-bounce-to-left:focus,
.hvr-bounce-to-left:active {
	color: white;
}

.hvr-bounce-to-left:hover:before,
.hvr-bounce-to-left:focus:before,
.hvr-bounce-to-left:active:before {
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1);
	-webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Bounce To Bottom */

.hvr-bounce-to-bottom {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}

.hvr-bounce-to-bottom:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: 50% 0;
	-ms-transform-origin: 50% 0;
	transform-origin: 50% 0;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-bounce-to-bottom:hover,
.hvr-bounce-to-bottom:focus,
.hvr-bounce-to-bottom:active {
	color: white;
}

.hvr-bounce-to-bottom:hover:before,
.hvr-bounce-to-bottom:focus:before,
.hvr-bounce-to-bottom:active:before {
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	transform: scaleY(1);
	-webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Bounce To Top */

.hvr-bounce-to-top {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}

.hvr-bounce-to-top:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-bounce-to-top:hover,
.hvr-bounce-to-top:focus,
.hvr-bounce-to-top:active {
	color: white;
}

.hvr-bounce-to-top:hover:before,
.hvr-bounce-to-top:focus:before,
.hvr-bounce-to-top:active:before {
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	transform: scaleY(1);
	-webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Radial Out */

.hvr-radial-out {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
	background: #e1e1e1;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-radial-out:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	-webkit-border-radius: 100%;
	border-radius: 100%;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-radial-out:hover,
.hvr-radial-out:focus,
.hvr-radial-out:active {
	color: white;
}

.hvr-radial-out:hover:before,
.hvr-radial-out:focus:before,
.hvr-radial-out:active:before {
	-webkit-transform: scale(2);
	-ms-transform: scale(2);
	transform: scale(2);
}

/* Radial In */

.hvr-radial-in {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
	background: #fff;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-radial-in:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #e1e1e1;
	-webkit-border-radius: 100%;
	border-radius: 100%;
	-webkit-transform: scale(2);
	-ms-transform: scale(2);
	transform: scale(2);
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-radial-in:hover,
.hvr-radial-in:focus,
.hvr-radial-in:active {
	color: white;
}

.hvr-radial-in:hover:before,
.hvr-radial-in:focus:before,
.hvr-radial-in:active:before {
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
}

/* Rectangle In */

.hvr-rectangle-in {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	background: #fff;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-rectangle-in:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #e1e1e1;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-rectangle-in:hover,
.hvr-rectangle-in:focus,
.hvr-rectangle-in:active {
	color: white;
}

.hvr-rectangle-in:hover:before,
.hvr-rectangle-in:focus:before,
.hvr-rectangle-in:active:before {
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
}

/* Rectangle Out */

.hvr-rectangle-out {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	background: #e1e1e1;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-rectangle-out:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-rectangle-out:hover,
.hvr-rectangle-out:focus,
.hvr-rectangle-out:active {
	color: white;
}

.hvr-rectangle-out:hover:before,
.hvr-rectangle-out:focus:before,
.hvr-rectangle-out:active:before {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

/* Shutter In Horizontal */

.hvr-shutter-in-horizontal {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	background: #fff;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-shutter-in-horizontal:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #e1e1e1;
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1);
	-webkit-transform-origin: 50%;
	-ms-transform-origin: 50%;
	transform-origin: 50%;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-shutter-in-horizontal:hover,
.hvr-shutter-in-horizontal:focus,
.hvr-shutter-in-horizontal:active {
	color: white;
}

.hvr-shutter-in-horizontal:hover:before,
.hvr-shutter-in-horizontal:focus:before,
.hvr-shutter-in-horizontal:active:before {
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
}

/* Shutter Out Horizontal */

.hvr-shutter-out-horizontal {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	background: #e1e1e1;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-shutter-out-horizontal:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #fff;
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 50%;
	-ms-transform-origin: 50%;
	transform-origin: 50%;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-shutter-out-horizontal:hover,
.hvr-shutter-out-horizontal:focus,
.hvr-shutter-out-horizontal:active {
	color: white;
}

.hvr-shutter-out-horizontal:hover:before,
.hvr-shutter-out-horizontal:focus:before,
.hvr-shutter-out-horizontal:active:before {
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1);
}

/* Shutter In Vertical */

.hvr-shutter-in-vertical {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	background: #fff;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-shutter-in-vertical:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #e1e1e1;
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	transform: scaleY(1);
	-webkit-transform-origin: 50%;
	-ms-transform-origin: 50%;
	transform-origin: 50%;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-shutter-in-vertical:hover,
.hvr-shutter-in-vertical:focus,
.hvr-shutter-in-vertical:active {
	color: white;
}

.hvr-shutter-in-vertical:hover:before,
.hvr-shutter-in-vertical:focus:before,
.hvr-shutter-in-vertical:active:before {
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	transform: scaleY(0);
}

/* Shutter Out Vertical */

.hvr-shutter-out-vertical {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	background: #e1e1e1;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-shutter-out-vertical:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #fff;
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: 50%;
	-ms-transform-origin: 50%;
	transform-origin: 50%;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-shutter-out-vertical:hover,
.hvr-shutter-out-vertical:focus,
.hvr-shutter-out-vertical:active {
	color: white;
}

.hvr-shutter-out-vertical:hover:before,
.hvr-shutter-out-vertical:focus:before,
.hvr-shutter-out-vertical:active:before {
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	transform: scaleY(1);
}

/* BORDER TRANSITIONS */

/* Border Fade */

.hvr-border-fade {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: box-shadow;
	-webkit-transition-property: -webkit-box-shadow;
	transition-property: -webkit-box-shadow;
	transition-property: box-shadow;
	transition-property: box-shadow, -webkit-box-shadow;
	-webkit-box-shadow: inset 0 0 0 4px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: inset 0 0 0 4px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0); /* Hack to improve aliasing on mobile/tablet devices */
}

.hvr-border-fade:hover,
.hvr-border-fade:focus,
.hvr-border-fade:active {
	-webkit-box-shadow: inset 0 0 0 4px #fff, 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: inset 0 0 0 4px #fff, 0 0 1px rgba(0, 0, 0, 0); /* Hack to improve aliasing on mobile/tablet devices */
}

/* Hollow */

.hvr-hollow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: background;
	transition-property: background;
	-webkit-box-shadow: inset 0 0 0 4px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: inset 0 0 0 4px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0); /* Hack to improve aliasing on mobile/tablet devices */
}

.hvr-hollow:hover,
.hvr-hollow:focus,
.hvr-hollow:active {
	background: none;
}

/* Trim */

.hvr-trim {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-trim:before {
	content: '';
	position: absolute;
	border: white solid 4px;
	top: 4px;
	left: 4px;
	right: 4px;
	bottom: 4px;
	opacity: 0;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: opacity;
	transition-property: opacity;
}

.hvr-trim:hover:before,
.hvr-trim:focus:before,
.hvr-trim:active:before {
	opacity: 1;
}

/* Ripple Out */

.hvr-ripple-out {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-ripple-out:before {
	content: '';
	position: absolute;
	border: #e1e1e1 solid 6px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
}

.hvr-ripple-out:hover:before,
.hvr-ripple-out:focus:before,
.hvr-ripple-out:active:before {
	-webkit-animation-name: hvr-ripple-out;
	animation-name: hvr-ripple-out;
}

/* Ripple In */

.hvr-ripple-in {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-ripple-in:before {
	content: '';
	position: absolute;
	border: #e1e1e1 solid 4px;
	top: -12px;
	right: -12px;
	bottom: -12px;
	left: -12px;
	opacity: 0;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
}

.hvr-ripple-in:hover:before,
.hvr-ripple-in:focus:before,
.hvr-ripple-in:active:before {
	-webkit-animation-name: hvr-ripple-in;
	animation-name: hvr-ripple-in;
}

/* Outline Out */

.hvr-outline-out {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-outline-out:before {
	content: '';
	position: absolute;
	border: #e1e1e1 solid 4px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: top, right, bottom, left;
	transition-property: top, right, bottom, left;
}

.hvr-outline-out:hover:before,
.hvr-outline-out:focus:before,
.hvr-outline-out:active:before {
	top: -8px;
	right: -8px;
	bottom: -8px;
	left: -8px;
}

/* Outline In */

.hvr-outline-in {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-outline-in:before {
	pointer-events: none;
	content: '';
	position: absolute;
	border: #e1e1e1 solid 4px;
	top: -16px;
	right: -16px;
	bottom: -16px;
	left: -16px;
	opacity: 0;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: top, right, bottom, left;
	transition-property: top, right, bottom, left;
}

.hvr-outline-in:hover:before,
.hvr-outline-in:focus:before,
.hvr-outline-in:active:before {
	top: -8px;
	right: -8px;
	bottom: -8px;
	left: -8px;
	opacity: 1;
}

/* Round Corners */

.hvr-round-corners {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: border-radius;
	-webkit-transition-property: -webkit-border-radius;
	transition-property: -webkit-border-radius;
	transition-property: border-radius;
	transition-property: border-radius, -webkit-border-radius;
}

.hvr-round-corners:hover,
.hvr-round-corners:focus,
.hvr-round-corners:active {
	-webkit-border-radius: 1em;
	border-radius: 1em;
}

/* Underline From Left */

.hvr-underline-from-left {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hvr-underline-from-left:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	right: 100%;
	bottom: 0;
	background: #fff;
	height: 4px;
	-webkit-transition-property: right;
	transition-property: right;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-underline-from-left:hover:before,
.hvr-underline-from-left:focus:before,
.hvr-underline-from-left:active:before {
	right: 0;
}

/* Underline From Center */

.hvr-underline-from-center {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hvr-underline-from-center:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 51%;
	right: 51%;
	bottom: 0;
	background: #fff;
	height: 4px;
	-webkit-transition-property: left, right;
	transition-property: left, right;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-underline-from-center:hover:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:active:before {
	left: 0;
	right: 0;
}

/* Underline From Right */

.hvr-underline-from-right {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hvr-underline-from-right:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 100%;
	right: 0;
	bottom: 0;
	background: #fff;
	height: 4px;
	-webkit-transition-property: left;
	transition-property: left;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-underline-from-right:hover:before,
.hvr-underline-from-right:focus:before,
.hvr-underline-from-right:active:before {
	left: 0;
}

/* Overline From Left */

.hvr-overline-from-left {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hvr-overline-from-left:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	right: 100%;
	top: 0;
	background: #fff;
	height: 4px;
	-webkit-transition-property: right;
	transition-property: right;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-overline-from-left:hover:before,
.hvr-overline-from-left:focus:before,
.hvr-overline-from-left:active:before {
	right: 0;
}

/* Overline From Center */

.hvr-overline-from-center {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hvr-overline-from-center:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 51%;
	right: 51%;
	top: 0;
	background: #fff;
	height: 4px;
	-webkit-transition-property: left, right;
	transition-property: left, right;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-overline-from-center:hover:before,
.hvr-overline-from-center:focus:before,
.hvr-overline-from-center:active:before {
	left: 0;
	right: 0;
}

/* Overline From Right */

.hvr-overline-from-right {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hvr-overline-from-right:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 100%;
	right: 0;
	top: 0;
	background: #fff;
	height: 4px;
	-webkit-transition-property: left;
	transition-property: left;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-overline-from-right:hover:before,
.hvr-overline-from-right:focus:before,
.hvr-overline-from-right:active:before {
	left: 0;
}

/* Reveal */

.hvr-reveal {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hvr-reveal:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border-color: #fff;
	border-style: solid;
	border-width: 0;
	-webkit-transition-property: border-width;
	transition-property: border-width;
	-webkit-transition-duration: 0.1s;
	transition-duration: 0.1s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-reveal:hover:before,
.hvr-reveal:focus:before,
.hvr-reveal:active:before {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	border-width: 4px;
}

/* Underline Reveal */

.hvr-underline-reveal {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hvr-underline-reveal:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	height: 4px;
	-webkit-transform: translateY(4px);
	-ms-transform: translateY(4px);
	transform: translateY(4px);
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-underline-reveal:hover:before,
.hvr-underline-reveal:focus:before,
.hvr-underline-reveal:active:before {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

/* Overline Reveal */

.hvr-overline-reveal {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hvr-overline-reveal:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	right: 0;
	top: 0;
	background: #fff;
	height: 4px;
	-webkit-transform: translateY(-4px);
	-ms-transform: translateY(-4px);
	transform: translateY(-4px);
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-overline-reveal:hover:before,
.hvr-overline-reveal:focus:before,
.hvr-overline-reveal:active:before {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

/* SHADOW/GLOW TRANSITIONS */

/* Glow */

.hvr-glow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: box-shadow;
	-webkit-transition-property: -webkit-box-shadow;
	transition-property: -webkit-box-shadow;
	transition-property: box-shadow;
	transition-property: box-shadow, -webkit-box-shadow;
}

.hvr-glow:hover,
.hvr-glow:focus,
.hvr-glow:active {
	-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

/* Shadow */

.hvr-shadow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: box-shadow;
	-webkit-transition-property: -webkit-box-shadow;
	transition-property: -webkit-box-shadow;
	transition-property: box-shadow;
	transition-property: box-shadow, -webkit-box-shadow;
}

.hvr-shadow:hover,
.hvr-shadow:focus,
.hvr-shadow:active {
	-webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
	box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
}

/* Grow Shadow */

.hvr-grow-shadow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: box-shadow, transform;
	-webkit-transition-property: -webkit-box-shadow, -webkit-transform;
	transition-property: -webkit-box-shadow, -webkit-transform;
	transition-property: box-shadow, transform;
	transition-property: box-shadow, transform, -webkit-box-shadow, -webkit-transform;
}

.hvr-grow-shadow:hover,
.hvr-grow-shadow:focus,
.hvr-grow-shadow:active {
	-webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
	box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

/* Box Shadow Outset */

.hvr-box-shadow-outset {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: box-shadow;
	-webkit-transition-property: -webkit-box-shadow;
	transition-property: -webkit-box-shadow;
	transition-property: box-shadow;
	transition-property: box-shadow, -webkit-box-shadow;
}

.hvr-box-shadow-outset:hover,
.hvr-box-shadow-outset:focus,
.hvr-box-shadow-outset:active {
	-webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}

/* Box Shadow Inset */

.hvr-box-shadow-inset {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: box-shadow;
	-webkit-transition-property: -webkit-box-shadow;
	transition-property: -webkit-box-shadow;
	transition-property: box-shadow;
	transition-property: box-shadow, -webkit-box-shadow;
	-webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.6), 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.6), 0 0 1px rgba(0, 0, 0, 0); /* Hack to improve aliasing on mobile/tablet devices */
}

.hvr-box-shadow-inset:hover,
.hvr-box-shadow-inset:focus,
.hvr-box-shadow-inset:active {
	-webkit-box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.6), 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.6), 0 0 1px rgba(0, 0, 0, 0); /* Hack to improve aliasing on mobile/tablet devices */
}

/* Float Shadow */

.hvr-float-shadow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-float-shadow:before {
	pointer-events: none;
	position: absolute;
	z-index: -1;
	content: '';
	top: 100%;
	left: 5%;
	height: 10px;
	width: 90%;
	opacity: 0;
	background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%); /* W3C */
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform, opacity;
	-webkit-transition-property: opacity, -webkit-transform;
	transition-property: opacity, -webkit-transform;
	transition-property: transform, opacity;
	transition-property: transform, opacity, -webkit-transform;
}

.hvr-float-shadow:hover,
.hvr-float-shadow:focus,
.hvr-float-shadow:active {
	-webkit-transform: translateY(-5px);
	-ms-transform: translateY(-5px);
	transform: translateY(-5px); /* move the element up by 5px */
}

.hvr-float-shadow:hover:before,
.hvr-float-shadow:focus:before,
.hvr-float-shadow:active:before {
	opacity: 1;
	-webkit-transform: translateY(5px);
	-ms-transform: translateY(5px);
	transform: translateY(5px); /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}

/* Shadow Radial */

.hvr-shadow-radial {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-shadow-radial:before,
.hvr-shadow-radial:after {
	pointer-events: none;
	position: absolute;
	content: '';
	left: 0;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background-repeat: no-repeat;
	height: 5px;
	opacity: 0;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: opacity;
	transition-property: opacity;
}

.hvr-shadow-radial:before {
	bottom: 100%;
	background: radial-gradient(ellipse at 50% 150%, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 80%);
}

.hvr-shadow-radial:after {
	top: 100%;
	background: radial-gradient(ellipse at 50% -50%, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 80%);
}

.hvr-shadow-radial:hover:before,
.hvr-shadow-radial:focus:before,
.hvr-shadow-radial:active:before,
.hvr-shadow-radial:hover:after,
.hvr-shadow-radial:focus:after,
.hvr-shadow-radial:active:after {
	opacity: 1;
}

/* SPEECH BUBBLES */

/* Bubble Top */

.hvr-bubble-top {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-bubble-top:before {
	pointer-events: none;
	position: absolute;
	z-index: -1;
	content: '';
	border-style: solid;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	left: -webkit-calc(50% - 10px);
	left: calc(50% - 10px);
	top: 0;
	border-width: 0 10px 10px 10px;
	border-color: transparent transparent #e1e1e1 transparent;
}

.hvr-bubble-top:hover:before,
.hvr-bubble-top:focus:before,
.hvr-bubble-top:active:before {
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
}

/* Bubble Right */

.hvr-bubble-right {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-bubble-right:before {
	pointer-events: none;
	position: absolute;
	z-index: -1;
	content: '';
	border-style: solid;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	top: -webkit-calc(50% - 10px);
	top: calc(50% - 10px);
	right: 0;
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent #e1e1e1;
}

.hvr-bubble-right:hover:before,
.hvr-bubble-right:focus:before,
.hvr-bubble-right:active:before {
	-webkit-transform: translateX(10px);
	-ms-transform: translateX(10px);
	transform: translateX(10px);
}

/* Bubble Bottom */

.hvr-bubble-bottom {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-bubble-bottom:before {
	pointer-events: none;
	position: absolute;
	z-index: -1;
	content: '';
	border-style: solid;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	left: -webkit-calc(50% - 10px);
	left: calc(50% - 10px);
	bottom: 0;
	border-width: 10px 10px 0 10px;
	border-color: #e1e1e1 transparent transparent transparent;
}

.hvr-bubble-bottom:hover:before,
.hvr-bubble-bottom:focus:before,
.hvr-bubble-bottom:active:before {
	-webkit-transform: translateY(10px);
	-ms-transform: translateY(10px);
	transform: translateY(10px);
}

/* Bubble Left */

.hvr-bubble-left {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-bubble-left:before {
	pointer-events: none;
	position: absolute;
	z-index: -1;
	content: '';
	border-style: solid;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	top: -webkit-calc(50% - 10px);
	top: calc(50% - 10px);
	left: 0;
	border-width: 10px 10px 10px 0;
	border-color: transparent #e1e1e1 transparent transparent;
}

.hvr-bubble-left:hover:before,
.hvr-bubble-left:focus:before,
.hvr-bubble-left:active:before {
	-webkit-transform: translateX(-10px);
	-ms-transform: translateX(-10px);
	transform: translateX(-10px);
}

/* Bubble Float Top */

.hvr-bubble-float-top {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-bubble-float-top:before {
	position: absolute;
	z-index: -1;
	content: '';
	left: -webkit-calc(50% - 10px);
	left: calc(50% - 10px);
	top: 0;
	border-style: solid;
	border-width: 0 10px 10px 10px;
	border-color: transparent transparent #e1e1e1 transparent;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-bubble-float-top:hover,
.hvr-bubble-float-top:focus,
.hvr-bubble-float-top:active {
	-webkit-transform: translateY(10px);
	-ms-transform: translateY(10px);
	transform: translateY(10px);
}

.hvr-bubble-float-top:hover:before,
.hvr-bubble-float-top:focus:before,
.hvr-bubble-float-top:active:before {
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
}

/* Bubble Float Right */

.hvr-bubble-float-right {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-bubble-float-right:before {
	position: absolute;
	z-index: -1;
	top: -webkit-calc(50% - 10px);
	top: calc(50% - 10px);
	right: 0;
	content: '';
	border-style: solid;
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent #e1e1e1;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-bubble-float-right:hover,
.hvr-bubble-float-right:focus,
.hvr-bubble-float-right:active {
	-webkit-transform: translateX(-10px);
	-ms-transform: translateX(-10px);
	transform: translateX(-10px);
}

.hvr-bubble-float-right:hover:before,
.hvr-bubble-float-right:focus:before,
.hvr-bubble-float-right:active:before {
	-webkit-transform: translateX(10px);
	-ms-transform: translateX(10px);
	transform: translateX(10px);
}

/* Bubble Float Bottom */

.hvr-bubble-float-bottom {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-bubble-float-bottom:before {
	position: absolute;
	z-index: -1;
	content: '';
	left: -webkit-calc(50% - 10px);
	left: calc(50% - 10px);
	bottom: 0;
	border-style: solid;
	border-width: 10px 10px 0 10px;
	border-color: #e1e1e1 transparent transparent transparent;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-bubble-float-bottom:hover,
.hvr-bubble-float-bottom:focus,
.hvr-bubble-float-bottom:active {
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
}

.hvr-bubble-float-bottom:hover:before,
.hvr-bubble-float-bottom:focus:before,
.hvr-bubble-float-bottom:active:before {
	-webkit-transform: translateY(10px);
	-ms-transform: translateY(10px);
	transform: translateY(10px);
}

/* Bubble Float Left */

.hvr-bubble-float-left {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-bubble-float-left:before {
	position: absolute;
	z-index: -1;
	content: '';
	top: -webkit-calc(50% - 10px);
	top: calc(50% - 10px);
	left: 0;
	border-style: solid;
	border-width: 10px 10px 10px 0;
	border-color: transparent #e1e1e1 transparent transparent;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hvr-bubble-float-left:hover,
.hvr-bubble-float-left:focus,
.hvr-bubble-float-left:active {
	-webkit-transform: translateX(10px);
	-ms-transform: translateX(10px);
	transform: translateX(10px);
}

.hvr-bubble-float-left:hover:before,
.hvr-bubble-float-left:focus:before,
.hvr-bubble-float-left:active:before {
	-webkit-transform: translateX(-10px);
	-ms-transform: translateX(-10px);
	transform: translateX(-10px);
}

/* ICONS */

/* Icon Back */

.hvr-icon-back {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.1s;
	transition-duration: 0.1s;
}

.hvr-icon-back .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.1s;
	transition-duration: 0.1s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-back:hover .hvr-icon,
.hvr-icon-back:focus .hvr-icon,
.hvr-icon-back:active .hvr-icon {
	-webkit-transform: translateX(-4px);
	-ms-transform: translateX(-4px);
	transform: translateX(-4px);
}

/* Icon Forward */

.hvr-icon-forward {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.1s;
	transition-duration: 0.1s;
}

.hvr-icon-forward .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.1s;
	transition-duration: 0.1s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-forward:hover .hvr-icon,
.hvr-icon-forward:focus .hvr-icon,
.hvr-icon-forward:active .hvr-icon {
	-webkit-transform: translateX(4px);
	-ms-transform: translateX(4px);
	transform: translateX(4px);
}

/* Icon Down */

/* Icon Down */

.hvr-icon-down {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-icon-down .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.hvr-icon-down:hover .hvr-icon,
.hvr-icon-down:focus .hvr-icon,
.hvr-icon-down:active .hvr-icon {
	-webkit-animation-name: hvr-icon-down;
	animation-name: hvr-icon-down;
	-webkit-animation-duration: 0.75s;
	animation-duration: 0.75s;
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
}

/* Icon Up */

/* Icon Up */

.hvr-icon-up {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-icon-up .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.hvr-icon-up:hover .hvr-icon,
.hvr-icon-up:focus .hvr-icon,
.hvr-icon-up:active .hvr-icon {
	-webkit-animation-name: hvr-icon-up;
	animation-name: hvr-icon-up;
	-webkit-animation-duration: 0.75s;
	animation-duration: 0.75s;
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
}

/* Icon Spin */

.hvr-icon-spin {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-icon-spin .hvr-icon {
	-webkit-transition-duration: 1s;
	transition-duration: 1s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-in-out;
	transition-timing-function: ease-in-out;
}

.hvr-icon-spin:hover .hvr-icon,
.hvr-icon-spin:focus .hvr-icon,
.hvr-icon-spin:active .hvr-icon {
	-webkit-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	transform: rotate(360deg);
}

/* Icon Drop */

/* Icon Drop */

.hvr-icon-drop {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-icon-drop .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.hvr-icon-drop:hover .hvr-icon,
.hvr-icon-drop:focus .hvr-icon,
.hvr-icon-drop:active .hvr-icon {
	opacity: 0;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-animation-name: hvr-icon-drop;
	animation-name: hvr-icon-drop;
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	animation-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Icon Fade */

.hvr-icon-fade {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-icon-fade .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-property: color;
	transition-property: color;
}

.hvr-icon-fade:hover .hvr-icon,
.hvr-icon-fade:focus .hvr-icon,
.hvr-icon-fade:active .hvr-icon {
	color: #0F9E5E;
}

/* Icon Float Away */

/* Icon Float Away */

.hvr-icon-float-away {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-icon-float-away .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.hvr-icon-float-away:hover .hvr-icon,
.hvr-icon-float-away:focus .hvr-icon,
.hvr-icon-float-away:active .hvr-icon {
	-webkit-animation-name: hvr-icon-float-away;
	animation-name: hvr-icon-float-away;
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
}

/* Icon Sink Away */

/* Icon Sink Away */

.hvr-icon-sink-away {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-icon-sink-away .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.hvr-icon-sink-away:hover .hvr-icon,
.hvr-icon-sink-away:focus .hvr-icon,
.hvr-icon-sink-away:active .hvr-icon {
	-webkit-animation-name: hvr-icon-sink-away;
	animation-name: hvr-icon-sink-away;
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
}

/* Icon Grow */

.hvr-icon-grow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-grow .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-grow:hover .hvr-icon,
.hvr-icon-grow:focus .hvr-icon,
.hvr-icon-grow:active .hvr-icon {
	-webkit-transform: scale(1.3) translateZ(0);
	transform: scale(1.3) translateZ(0);
}

/* Icon Shrink */

.hvr-icon-shrink {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-shrink .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-shrink:hover .hvr-icon,
.hvr-icon-shrink:focus .hvr-icon,
.hvr-icon-shrink:active .hvr-icon {
	-webkit-transform: scale(0.8);
	-ms-transform: scale(0.8);
	transform: scale(0.8);
}

/* Icon Pulse */

.hvr-icon-pulse {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-icon-pulse .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-pulse:hover .hvr-icon,
.hvr-icon-pulse:focus .hvr-icon,
.hvr-icon-pulse:active .hvr-icon {
	-webkit-animation-name: hvr-icon-pulse;
	animation-name: hvr-icon-pulse;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

/* Icon Pulse Grow */

.hvr-icon-pulse-grow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-icon-pulse-grow .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-pulse-grow:hover .hvr-icon,
.hvr-icon-pulse-grow:focus .hvr-icon,
.hvr-icon-pulse-grow:active .hvr-icon {
	-webkit-animation-name: hvr-icon-pulse-grow;
	animation-name: hvr-icon-pulse-grow;
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	animation-direction: alternate;
}

/* Icon Pulse Shrink */

.hvr-icon-pulse-shrink {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-icon-pulse-shrink .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-pulse-shrink:hover .hvr-icon,
.hvr-icon-pulse-shrink:focus .hvr-icon,
.hvr-icon-pulse-shrink:active .hvr-icon {
	-webkit-animation-name: hvr-icon-pulse-shrink;
	animation-name: hvr-icon-pulse-shrink;
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	animation-direction: alternate;
}

/* Icon Push */

.hvr-icon-push {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-push .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-push:hover .hvr-icon,
.hvr-icon-push:focus .hvr-icon,
.hvr-icon-push:active .hvr-icon {
	-webkit-animation-name: hvr-icon-push;
	animation-name: hvr-icon-push;
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Icon Pop */

.hvr-icon-pop {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-pop .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-pop:hover .hvr-icon,
.hvr-icon-pop:focus .hvr-icon,
.hvr-icon-pop:active .hvr-icon {
	-webkit-animation-name: hvr-icon-pop;
	animation-name: hvr-icon-pop;
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Icon Bounce */

.hvr-icon-bounce {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-bounce .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-bounce:hover .hvr-icon,
.hvr-icon-bounce:focus .hvr-icon,
.hvr-icon-bounce:active .hvr-icon {
	-webkit-transform: scale(1.5);
	-ms-transform: scale(1.5);
	transform: scale(1.5);
	-webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
	transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Icon Rotate */

.hvr-icon-rotate {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-rotate .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-rotate:hover .hvr-icon,
.hvr-icon-rotate:focus .hvr-icon,
.hvr-icon-rotate:active .hvr-icon {
	-webkit-transform: rotate(20deg);
	-ms-transform: rotate(20deg);
	transform: rotate(20deg);
}

/* Icon Grow Rotate */

.hvr-icon-grow-rotate {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-grow-rotate .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-grow-rotate:hover .hvr-icon,
.hvr-icon-grow-rotate:focus .hvr-icon,
.hvr-icon-grow-rotate:active .hvr-icon {
	-webkit-transform: scale(1.5) rotate(12deg);
	-ms-transform: scale(1.5) rotate(12deg);
	transform: scale(1.5) rotate(12deg);
}

/* Icon Float */

.hvr-icon-float {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-float .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-float:hover .hvr-icon,
.hvr-icon-float:focus .hvr-icon,
.hvr-icon-float:active .hvr-icon {
	-webkit-transform: translateY(-4px);
	-ms-transform: translateY(-4px);
	transform: translateY(-4px);
}

/* Icon Sink */

.hvr-icon-sink {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-sink .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-icon-sink:hover .hvr-icon,
.hvr-icon-sink:focus .hvr-icon,
.hvr-icon-sink:active .hvr-icon {
	-webkit-transform: translateY(4px);
	-ms-transform: translateY(4px);
	transform: translateY(4px);
}

/* Icon Bob */

.hvr-icon-bob {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-bob .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.hvr-icon-bob:hover .hvr-icon,
.hvr-icon-bob:focus .hvr-icon,
.hvr-icon-bob:active .hvr-icon {
	-webkit-animation-name: hvr-icon-bob-float, hvr-icon-bob;
	animation-name: hvr-icon-bob-float, hvr-icon-bob;
	-webkit-animation-duration: .3s, 1.5s;
	animation-duration: .3s, 1.5s;
	-webkit-animation-delay: 0s, .3s;
	animation-delay: 0s, .3s;
	-webkit-animation-timing-function: ease-out, ease-in-out;
	animation-timing-function: ease-out, ease-in-out;
	-webkit-animation-iteration-count: 1, infinite;
	animation-iteration-count: 1, infinite;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-direction: normal, alternate;
	animation-direction: normal, alternate;
}

/* Icon Hang */

.hvr-icon-hang {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-hang .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.hvr-icon-hang:hover .hvr-icon,
.hvr-icon-hang:focus .hvr-icon,
.hvr-icon-hang:active .hvr-icon {
	-webkit-animation-name: hvr-icon-hang-sink, hvr-icon-hang;
	animation-name: hvr-icon-hang-sink, hvr-icon-hang;
	-webkit-animation-duration: .3s, 1.5s;
	animation-duration: .3s, 1.5s;
	-webkit-animation-delay: 0s, .3s;
	animation-delay: 0s, .3s;
	-webkit-animation-timing-function: ease-out, ease-in-out;
	animation-timing-function: ease-out, ease-in-out;
	-webkit-animation-iteration-count: 1, infinite;
	animation-iteration-count: 1, infinite;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-direction: normal, alternate;
	animation-direction: normal, alternate;
}

/* Icon Wobble Horizontal */

.hvr-icon-wobble-horizontal {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-wobble-horizontal .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.hvr-icon-wobble-horizontal:hover .hvr-icon,
.hvr-icon-wobble-horizontal:focus .hvr-icon,
.hvr-icon-wobble-horizontal:active .hvr-icon {
	-webkit-animation-name: hvr-icon-wobble-horizontal;
	animation-name: hvr-icon-wobble-horizontal;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Icon Wobble Vertical */

.hvr-icon-wobble-vertical {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-wobble-vertical .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.hvr-icon-wobble-vertical:hover .hvr-icon,
.hvr-icon-wobble-vertical:focus .hvr-icon,
.hvr-icon-wobble-vertical:active .hvr-icon {
	-webkit-animation-name: hvr-icon-wobble-vertical;
	animation-name: hvr-icon-wobble-vertical;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* Icon Buzz */

.hvr-icon-buzz {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-buzz .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.hvr-icon-buzz:hover .hvr-icon,
.hvr-icon-buzz:focus .hvr-icon,
.hvr-icon-buzz:active .hvr-icon {
	-webkit-animation-name: hvr-icon-buzz;
	animation-name: hvr-icon-buzz;
	-webkit-animation-duration: 0.15s;
	animation-duration: 0.15s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

/* Icon Buzz Out */

.hvr-icon-buzz-out {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.hvr-icon-buzz-out .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.hvr-icon-buzz-out:hover .hvr-icon,
.hvr-icon-buzz-out:focus .hvr-icon,
.hvr-icon-buzz-out:active .hvr-icon {
	-webkit-animation-name: hvr-icon-buzz-out;
	animation-name: hvr-icon-buzz-out;
	-webkit-animation-duration: 0.75s;
	animation-duration: 0.75s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* CURLS */

/* Curl Top Left */

.hvr-curl-top-left {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-curl-top-left:before {
	pointer-events: none;
	position: absolute;
	content: '';
	height: 0;
	width: 0;
	top: 0;
	left: 0;
	background: white; /* IE9 */
	background: linear-gradient(135deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ffffff', endColorstr='#000000'); /*For IE7-8-9*/
	z-index: 1000;
	-webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: width, height;
	transition-property: width, height;
}

.hvr-curl-top-left:hover:before,
.hvr-curl-top-left:focus:before,
.hvr-curl-top-left:active:before {
	width: 25px;
	height: 25px;
}

/* Curl Top Right */

.hvr-curl-top-right {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-curl-top-right:before {
	pointer-events: none;
	position: absolute;
	content: '';
	height: 0;
	width: 0;
	top: 0;
	right: 0;
	background: white; /* IE9 */
	background: linear-gradient(225deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
	-webkit-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
	box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: width, height;
	transition-property: width, height;
}

.hvr-curl-top-right:hover:before,
.hvr-curl-top-right:focus:before,
.hvr-curl-top-right:active:before {
	width: 25px;
	height: 25px;
}

/* Curl Bottom Right */

.hvr-curl-bottom-right {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-curl-bottom-right:before {
	pointer-events: none;
	position: absolute;
	content: '';
	height: 0;
	width: 0;
	bottom: 0;
	right: 0;
	background: white; /* IE9 */
	background: linear-gradient(315deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
	-webkit-box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.4);
	box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.4);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: width, height;
	transition-property: width, height;
}

.hvr-curl-bottom-right:hover:before,
.hvr-curl-bottom-right:focus:before,
.hvr-curl-bottom-right:active:before {
	width: 25px;
	height: 25px;
}

/* Curl Bottom Left */

.hvr-curl-bottom-left {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
}

.hvr-curl-bottom-left:before {
	pointer-events: none;
	position: absolute;
	content: '';
	height: 0;
	width: 0;
	bottom: 0;
	left: 0;
	background: white; /* IE9 */
	background: linear-gradient(45deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
	-webkit-box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.4);
	box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.4);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: width, height;
	transition-property: width, height;
}

.hvr-curl-bottom-left:hover:before,
.hvr-curl-bottom-left:focus:before,
.hvr-curl-bottom-left:active:before {
	width: 25px;
	height: 25px;
}

@font-face {
	font-family: 'Supply';
	src: url(../fonts/supple/Supply-Regular.otf);
}

.desc-dn {
	display: none;
}

.mobile-dn {
	display: block;
}

.button-main {
	text-decoration: none;
	width: 12vw;
	height: 7.5vh;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: .7vw;
	color: #000000;
	letter-spacing: 0.07px;
	text-transform: uppercase;
	font-family: ClearSans, sans-serif;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: #eeeeee;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	cursor: pointer;
	border: 0;
}

.button-main:hover {
	color: #404040;
}

.button-main-bordered {
	text-decoration: none;
	cursor: pointer;
	width: 12vw;
	height: 7.5vh;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: .7vw;
	color: #ffffff;
	letter-spacing: 0.07px;
	text-transform: uppercase;
	font-family: ClearSans, sans-serif;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: transparent;
	border: 1.4px #ffffff solid;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.button-main-bordered:hover {
	color: #000;
}

.button-main-bordered:hover:before {
	height: 100%;
}

.button-main-bordered.black {
	color: #000;
	border-color: #000;
}

.button-main-bordered.black:before {
	background: #000;
}

.button-main-bordered.black:hover {
	color: #fff;
}

input[type="checkbox"]:checked,
input[type="checkbox"]:not(:checked),
input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}

input[type="checkbox"]:checked + label,
input[type="checkbox"]:not(:checked) + label,
input[type="radio"]:checked + label,
input[type="radio"]:not(:checked) + label {
	display: inline-block;
	position: relative;
	padding-left: 28px;
	line-height: 20px;
	cursor: pointer;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before,
input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 0px;
	width: 15px;
	height: 15px;
	border: 1px solid #dddddd;
	background-color: #ffffff;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
	-webkit-border-radius: 2px;
	border-radius: 2px;
}

input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
	-webkit-border-radius: 100%;
	border-radius: 100%;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after,
input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
	content: "";
	position: absolute;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
	left: 3px;
	top: 2px;
	width: 10px;
	height: 5px;
	-webkit-border-radius: 1px;
	border-radius: 1px;
	border-left: 4px solid #000;
	border-bottom: 4px solid #000;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
	left: 5px;
	top: 5px;
	width: 10px;
	height: 10px;
	-webkit-border-radius: 100%;
	border-radius: 100%;
	background-color: #000;
}

input[type="checkbox"]:not(:checked) + label:after,
input[type="radio"]:not(:checked) + label:after {
	opacity: 0;
}

input[type="checkbox"]:checked + label:after,
input[type="radio"]:checked + label:after {
	opacity: 1;
}

a {
	text-decoration: none;
}

h1 {
	font-size: 2.5vw;
	font-family: ClearSans-Bold, serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	line-height: 1.3em;
}

h2 {
	font-size: 2vw;
	font-family: ClearSans-Bold, serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	line-height: 1.6em;
}

h3 {
	font-family: ClearSans, sans-serif;
	font-size: 1.45vw;
	letter-spacing: .1vw;
	color: #000;
	font-weight: 100;
	margin: 0;
}

h4 {
	font-size: 1vw;
	font-family: ClearSans, sans-serif;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 100;
}

p {
	color: #363636;
}

.loader {
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	background: #fff;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.loader .logo-text {
	font-family: 'ClearSans-Bold', serif;
	text-transform: uppercase;
	font-size: 6.3vw;
	background-size: 100vw !important;
	background: url("../img/loader_image.jpg") center left no-repeat #171717;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	fill: transparent;
	display: inline;
	-webkit-animation: bg 25s linear;
	animation: bg 25s linear;
}

.loader .logo-line {
	width: 25vw;
	height: 3px;
	background: #e3e3e3;
	margin: 4vh auto;
	position: relative;
}

.loader .logo-line div {
	width: 0;
	float: left;
	height: 3px;
	background: #171717;
	-webkit-transition: .7s;
	transition: .7s;
}

.fixed-logo {
	cursor: pointer;
	position: fixed;
	top: 35px;
	left: 35px;
	z-index: 9;
}

.fixed-logo .logo-black {
	display: none;
}

.fixed-logo img {
	width: 10vw;
}

.fixed-menu {
	border-left: 1px solid rgba(255, 255, 255, 0.3);
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	z-index: 100;
	width: 18vw;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.fixed-menu .fixed-button {
	margin-top: 35px;
	z-index: 99;
}

.fixed-menu ul {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: .9vw;
	letter-spacing: .2px;
}

.fixed-menu ul li.active a {
	color: #fff;
}

.fixed-menu ul li {
	padding: 1.4vh 0;
}

.fixed-menu ul li a {
	color: #ccc;
	text-decoration: none;
	font-family: 'ClearSans-Bold', serif;
	text-transform: uppercase;
	position: relative;
	display: inline-block;
	padding: 1px 5px;
	z-index: 10;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.fixed-menu .menu {
	position: absolute;
	height: 100vh;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.fixed-menu .fixed-instagram {
	position: absolute;
	bottom: 55px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.fixed-menu .fixed-instagram .tel {
	padding-bottom: 15px;
}

.fixed-menu .fixed-instagram .inst-black {
	display: none;
}

.fixed-menu .fixed-instagram a {
	color: #fff;
	font-family: 'ClearSans-Bold', serif;
	font-size: .9vw;
	font-weight: 900;
}

.fixed-menu .fixed-instagram img {
	max-width: 82px;
}

.fixed-menu.black-menu {
	border-left: 1px solid rgba(0, 0, 0, 0.6);
}

.fixed-menu.black-menu .fixed-instagram a {
	color: #000;
}

.fixed-menu.black-menu a {
	color: #ccc;
}

.fixed-menu.black-menu .active a {
	color: #000;
}

.slider {
	position: absolute;
	top: 0;
	height: 100vh;
}

.slider .button-main-bordered {
	position: absolute;
	top: 0;
	right: 35px;
	margin-top: 35px;
}

.slider:before {
	content: '';
	display: block;
	width: 64px;
	height: 60%;
	position: absolute;
	background: url("../img/slider-l.png") center right;
	z-index: 100;
	left: 0;
	top: 12vw;
}

.slider:after {
	content: '';
	display: block;
	width: 64px;
	height: 60%;
	position: absolute;
	background: url("../img/slider.png") center right;
	z-index: 100;
	right: 0;
	top: 12vw;
}

.slider .catalog-arrows-all {
	position: absolute;
	top: 19vh;
	left: 4.515vw;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	font-family: ClearSans, sans-serif;
}

.slider .catalog-arrows-all .catalog-slider-arrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: .8vw;
	letter-spacing: .2px;
	text-transform: uppercase;
}

.slider .catalog-arrows-all .catalog-slider-arrow span {
	margin-right: .485vw;
}

.slider .catalog-arrows-all .catalog-slider-arrow .bordered-arrow img {
	width: 3.5vw;
	cursor: pointer;
}

.slider .owl-carousel {
	position: absolute;
	top: 27.5vh;
	width: 47vw;
	font-family: ClearSans, sans-serif;
}

.slider .owl-carousel h3 {
	margin-top: 15px;
}

.slider .owl-carousel .catalog-slide {
	padding-left: 6vw;
}

.slider .owl-carousel .catalog-slide .catalog-item-new {
	position: absolute;
	font-family: ClearSans-Bold, serif;
	z-index: 100;
	color: #29bc54;
	font-size: .8vw;
	top: 266px;
}

.slider .owl-carousel .catalog-slide .catalog-item-desc {
	color: #363636;
	font-size: .88vw;
	letter-spacing: .05vw;
	margin-bottom: .5rem;
}

.slider .owl-carousel .catalog-slide .catalog-item-temp {
	font-family: Supply;
	font-size: .80vw;
	color: #818181;
	margin-top: 25px;
	letter-spacing: 0.3vw;
}

.slider .arrow-left {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.slider .arrow-right {
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.slider .catalog-slide img {
	max-height: 265px;
	width: auto;
	margin-left: -13%;
}

.popup {
	position: fixed;
	background-color: #fff;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	height: 70vh;
	width: 57vw;
	top: 17.5vh;
	left: 11vw;
	color: #000;
	font-family: ClearSans, sans-serif;
	-webkit-transition: .5s ease-in;
	transition: .5s ease-in;
	opacity: 0;
	-webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
	z-index: -1;
}

.popup.active {
	z-index: 999;
	display: block !important;
	opacity: 1;
}

.popup h4 {
	text-transform: none;
}

.popup p {
	font-size: .9vw;
}

.popup .close-popup {
	position: absolute;
	right: 3.7vw;
	top: 2.7vw;
}

.popup .close-popup a {
	color: #000;
	font-size: .8vw;
	text-transform: uppercase;
}

.popup .close-popup img {
	width: 3vw;
	vertical-align: middle;
	padding-right: 10px;
}

.popup .popup-inner {
	padding: 3.7vw;
	height: 44.5vh;
	padding-top: 6vw;
}

.popup .checks {
	margin-bottom: -6.2vw;
	margin-left: -3.5vw;
}

.popup .checks ul > li {
	position: relative;
	margin-top: 5px;
	font-size: .7vw;
	list-style-type: none;
}

.popup .checks label {
	position: absolute;
	margin-left: 2px;
}

.popup input {
	-webkit-border-radius: 0;
	border-radius: 0;
	border: 0;
	border-bottom: 1px solid #C1C1C1;
	margin-bottom: 15px;
	width: 100%;
	margin-top: 10px;
	font-size: 10px;
	padding-bottom: 5px;
}

.popup .row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
}

.popup .row .col {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 48%;
}

.popup .row .col .button-main-bordered {
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	margin-top: 30px;
	margin-bottom: -30px;
}

.section {
	padding-right: 18vw;
}

.block1 {
	background-image: url("../img/microgreen-top.jpg");
	background-size: cover;
	color: #fff;
	padding-left: 13vw;
}

.block1 .buttons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.block1 .buttons > * {
	margin-right: 5px;
}

.block2 {
	background-size: 76vh;
	background-image: url(../img/catalog.png);
	background-repeat: no-repeat;
	background-position-x: 68vw;
}

.block2 .block-image {
	width: 32.2vw;
	background-image: url("../img/block-2-bg.jpg");
	height: 100vh;
}

.block2 .slider {
	left: 33vw;
	width: 47vw;
	top: -5vw;
}

.block2 .slider .catalog-arrows-all {
	left: 6vw;
}

.block2 .button-main-bordered {
	color: #000;
	border-color: #000;
	position: absolute;
	bottom: 5vw;
	left: 39vw;
}

.block3 {
	background: url(../img/grass.jpg) center no-repeat;
	background-size: cover;
	background-position-x: center;
	color: #fff;
	padding: 0 18vw 0 5vw;
}

.block4 .block-image {
	background: url("../img/block-4-image.jpg") no-repeat;
	background-position-x: center;
	width: 30vw;
	position: absolute;
	right: 0;
	height: 100vh;
	top: 0;
}

.block4 .owl-carousel {
	top: 16vh;
	width: 60vw;
}

.block4 .slider {
	left: 6vw;
	width: 62vw;
}

.block4 .slider:before {
	display: none;
}

.block4 .slider .button-main-bordered {
	bottom: 16vh;
	left: 26.6vw;
	top: unset;
}

.block4 .slider .client-name {
	font-size: 1.25vw;
	letter-spacing: .2vw;
	color: #000;
	text-transform: uppercase;
}

.block4 .slider .client-spec {
	padding-bottom: 3vh;
	padding-top: 1vh;
	font-size: 1vw;
	text-transform: uppercase;
	width: 82%;
}

.block4 .slider .client-message {
	font-size: 1vw;
	letter-spacing: .02vw;
	line-height: 3.2vh;
	color: #363636;
}

.block4 .slider .catalog-arrows-all {
	top: 16vh;
	left: 26.6vw;
	z-index: 99;
}

.block4 .slider .row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.block4 .slider .row .col-md-6 {
	width: 50%;
	padding: 0 0 2vw 2vw;
	margin-top: 10vh;
}

.block4 .slider .row .col-md-6:last-child {
	margin-left: 3vw;
}

.block4 .slider .row .col-md-6:first-child {
	width: 33%;
}

.block5 .mobile-phone img {
	width: 15vw;
}

.block5 .fp-tableCell {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.block5 .contacts {
	max-width: 25vw;
	margin-left: 10vw;
}

.block5 .contacts .place,
.block5 .contacts .inst {
	font-family: ClearSans-Bold, serif !important;
}

.block5 .contacts img {
	height: 14px;
	vertical-align: middle;
	padding-right: .5vw;
}

.block5 .contacts .ft-desc {
	font-size: 1vw;
	letter-spacing: .060vw;
	font-family: ClearSans, sans-serif;
	color: #363636;
}

.block5 .contacts .place,
.block5 .contacts .inst {
	font-size: .865vw;
	letter-spacing: .2px;
	padding-bottom: 2vh;
	font-family: ClearSans, sans-serif;
}

.block5 .contacts .ft-bot {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 30px;
}

.block5 .contacts .ft-bot .ft-links {
	padding-left: 2vw;
	font-size: .7vw;
	letter-spacing: .060vw;
	font-family: ClearSans, sans-serif;
	text-transform: uppercase;
}

.block5 .contacts .ft-bot .ft-links .ft-link {
	padding: 5px 0;
}

.block5 .contacts .ft-bot .ft-links p {
	margin: 0;
	padding: 0;
}

.ft-links {
	font-size: .7vw;
	letter-spacing: .060vw;
	font-family: ClearSans, sans-serif;
	text-transform: uppercase;
}

.ft-links .ft-link {
	padding: 5px 0;
}

.ft-links p {
	margin: 0;
	padding: 0;
	font-size: .7vw;
}

footer {
	font-family: ClearSans, sans-serif;
	color: #fff;
	background-color: #1c1c1c;
	font-size: 1vw;
}

footer.section {
	padding-right: 0;
}

footer a {
	opacity: .5;
	color: #fff;
	padding-bottom: 1.5vh;
}

footer span {
	opacity: .5;
}

footer p {
	color: #fff;
}

footer .fp-tableCell {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 100%;
}

footer .left {
	height: 25vh;
	width: 30vw;
	background: #000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-top: 5vw;
	padding-bottom: 5vw;
}

footer .left img {
	width: 15vw;
}

footer .left p {
	opacity: .5;
}

footer .center {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 5vw;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 25vw;
}

footer .center > * {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

footer .center h4 {
	padding-top: 0;
	margin-top: 0;
	text-transform: none;
}

footer .right {
	width: 30vw;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	padding-right: 5vw;
	padding-bottom: 5vw;
	padding-top: 5vw;
	margin-left: -5vw;
}

.mobile-menu-back {
	display: none;
	opacity: 0.8;
	background-color: #151515;
	height: 21vw;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 30;
	left: 0;
}

.full-catalog-item-name {
	font-size: 1.25vw;
	letter-spacing: .12vw;
	color: #000;
	margin-top: 25px;
	text-transform: uppercase;
	font-family: ClearSans, sans-serif;
}

.full-catalog-item-desc {
	font-size: .9vw;
	letter-spacing: .05vw;
	color: #363636;
	width: 69%;
	font-family: ClearSans, sans-serif;
}

.full-catalog-item-temp {
	font-family: 'Supply', sans-serif;
	font-size: .8vw;
	color: #818181;
	margin-top: 15px;
}

.full-catalog-item-new {
	position: absolute;
	top: 10px;
	font-family: ClearSans-Bold, serif;
	z-index: 10;
	color: #29bc54;
	font-size: .8vw;
}

.col-2 {
	position: relative;
	max-width: 17%;
	width: 100%;
}

.col-2 img {
	width: 100%;
}

.col-4 {
	max-width: 33%;
	width: 100%;
	position: relative;
}

.fhs {
	width: 70vw;
	height: 80vh;
	margin-top: 20vh;
	margin-left: 8vw;
}

.fhs .close-popup {
	position: absolute;
	top: 35px;
	right: 22vw;
	z-index: 999;
}

.fhs .close-popup a {
	color: #000;
	font-size: .8vw;
	text-transform: uppercase;
	font-family: ClearSans, sans-serif;
}

.fhs .close-popup img {
	width: 3vw;
	vertical-align: middle;
	padding-left: 10px;
}

.fhs:before {
	content: '';
	display: block;
	width: 80vw;
	height: 62px;
	position: absolute;
	top: 20vh;
	left: 0;
	z-index: 100;
	background: url(../img/grad-bottom.png) center top repeat-x;
}

.fhs:after {
	content: '';
	display: block;
	width: 80vw;
	height: 62px;
	position: absolute;
	bottom: -2px;
	left: 0;
	z-index: 100;
	background: url(../img/grad-top.png) center top repeat-x;
}

.fhs .fhs-scroll {
	overflow-x: hidden !important;
	position: relative;
	height: 80vh;
}

.fhs .row {
	margin-top: 5vh;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.partners-slide {
	width: 25vw;
	margin-left: 3vw;
}

.partners-slide img {
	width: 16vw;
}

.d-flex {
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

@media screen and (max-width: 600px) {

.mobile-dn {
	display: none;
}

.desc-dn {
	display: block;
}

.popup {
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: 99;
	display: none;
}

.popup .popup-logo {
	width: 30vw;
	position: absolute;
	left: 8vw;
	top: 35px;
}

.popup .checks ul > li {
	font-size: 3vw;
	list-style-type: none;
}

.popup .checks label {
	position: relative;
	margin-left: -10px;
}

.popup h3 {
	font-size: 5.5vw;
	margin-bottom: 10px;
}

.popup p {
	display: none;
}

.popup input {
	font-size: 3.1vw;
	padding-bottom: 10px;
}

.popup .popup-inner {
	height: 100%;
	padding: 0 8vw;
}

.popup .row {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.popup .row .col {
	width: 100%;
}

.popup .row .col .button-main-bordered {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.popup .close-popup {
	right: 7vw;
	top: 8vw;
}

.popup .close-popup img {
	padding: 0;
}

.popup .close-popup span {
	display: none;
}

.popup .close-popup img {
	width: 10vw;
}

h1 {
	font-size: 6.6vw;
	letter-spacing: 0;
}

h1 br {
	display: none;
}

h2 {
	font-size: 5.5vw;
	letter-spacing: 0;
}

h4 {
	font-size: 3.8vw;
}

.button-main-bordered,
.button-main {
	width: 48vw;
	height: 9.5vh;
	font-size: 3.1vw;
}

.fixed-menu {
	left: 8vw;
	top: 8vw;
	display: none;
}

.mobile-menu {
	position: fixed;
	right: 8vw;
	z-index: 99;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	top: 12vw;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.mobile-menu img {
	width: 10vw;
}

.mobile-menu-opened {
	display: none;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #151515;
	z-index: 990;
}

.mobile-menu-opened a {
	color: #fff;
	font-family: ClearSans-Bold, serif;
}

.mobile-menu-opened .header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-top: 3vw;
	padding-bottom: 2vw;
	border-bottom: 1px solid #fff;
}

.mobile-menu-opened .header .logo {
	width: 30vw;
	padding-left: 8vw;
}

.mobile-menu-opened .header .close {
	width: 10vw;
	margin-top: 2vw;
	padding-right: 8vw;
}

.mobile-menu-opened .main {
	padding-left: 8vw;
	padding-top: 20vw;
}

.mobile-menu-opened .main ul {
	padding: 0;
}

.mobile-menu-opened .main li {
	list-style-type: none;
	line-height: 10vw;
}

.mobile-menu-opened .main .button-main {
	margin-top: 20px;
}

.mobile-menu-opened .footer {
	position: fixed;
	bottom: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 86vw;
	margin: 0 auto;
	padding-left: 8vw;
	padding-bottom: 10vw;
}

.mobile-menu-opened .footer img {
	width: 6vw;
	vertical-align: middle;
	padding-left: 2vw;
}

.mobile-menu-opened .footer a {
	font-family: ClearSans, sans-serif;
	color: #dbdbdb;
}

.menu img {
	padding-left: 3vw;
}

.fixed-logo {
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	left: 8vw;
	top: 8vw;
	z-index: 99;
}

.fixed-logo img {
	width: 43vw;
}

.fixed-logo .mobile-logo {
	width: 30vw;
}

.section {
	padding: 0 8vw;
}

.block1 {
	padding-left: 8vw;
	background-image: url("../img/mobile-bg.jpg");
}

.block1 .buttons {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.block1 .buttons > * {
	margin-bottom: 1vh;
}

.block2 {
	background-image: none;
	padding-left: 0;
}

.block2 .block-image {
	display: none;
}

.block2 .block2-text {
	font-family: ClearSans, sans-serif;
	font-size: 3.8vw;
	text-align: left;
	margin-top: -10vh;
	margin-bottom: 8vh;
	padding-left: 8vw;
	padding-right: 8vw;
}

.block2 .button-main-bordered {
	bottom: 10vh;
}

.block2 .slider {
	width: 100%;
	left: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: column-reverse;
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	height: auto;
}

.block2 .slider:before {
	display: none;
}

.block2 .slider:after {
	display: none;
}

.block2 .slider .catalog-arrows-all {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 84vw;
	margin: 0 auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	top: 25%;
	left: unset;
}

.block2 .slider .catalog-arrows-all .catalog-slider-arrow .bordered-arrow {
	z-index: 22;
}

.block2 .slider .catalog-arrows-all .catalog-slider-arrow .bordered-arrow img {
	width: 10vw;
}

.block2 .slider .owl-carousel {
	left: 8vw;
	width: 100%;
	position: unset;
	margin-bottom: -5vh;
}

.block2 .slider .owl-carousel .catalog-slide {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	padding-left: 0;
}

.block2 .slider .owl-carousel .catalog-slide img {
	margin-left: 0;
	max-height: 48vw;
}

.block2 .slider .owl-carousel .catalog-item-name {
	font-size: 4.3vw;
}

.block2 .slider .owl-carousel .catalog-item-desc {
	font-size: 4vw;
	max-width: 80vw;
}

.block2 .slider .owl-carousel .catalog-item-temp {
	font-size: 3.5vw;
	margin-top: 10px;
}

.block2 .slider .owl-carousel .catalog-item-new {
	font-size: 4.5vw;
	top: 165px;
}

.block2 .button-main-bordered {
	left: 25vw;
	bottom: 6vh;
}

.block3 .button-main-bordered {
	display: none;
}

.block3 h4 {
	font-family: ClearSans-Bold, serif;
}

.block4 .block-image {
	display: none;
}

.block4 .owl-carousel .owl-item img {
	width: 150px;
}

.block4 .slider {
	padding-top: 10vh;
	width: 100%;
	left: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	FLEX-DIRECTION: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	height: auto;
}

.block4 .slider:before {
	display: none;
}

.block4 .slider:after {
	display: none;
}

.block4 .slider .button-main-bordered {
	position: relative;
	right: 0;
	left: 0;
	margin-top: 20vh;
}

.block4 .slider .catalog-arrows-all {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 84vw;
	margin: 0 auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	top: 28%;
	left: unset;
	z-index: 2;
}

.block4 .slider .catalog-arrows-all .catalog-slider-arrow .bordered-arrow {
	z-index: 999;
}

.block4 .slider .catalog-arrows-all .catalog-slider-arrow .bordered-arrow img {
	width: 10vw;
}

.block4 .slider .owl-carousel {
	left: 8vw;
	width: 100%;
	position: unset;
	margin-bottom: -5vh;
}

.block4 .slider .owl-carousel .catalog-slide {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	padding-left: 0;
}

.block4 .slider .owl-carousel .catalog-slide img {
	margin-left: 0;
	max-height: 55vw;
}

.block4 .slider .owl-carousel .catalog-slide .catalog-item-new {
	font-size: 5.8vw;
	top: 278px;
}

.block4 .slider .owl-carousel .catalog-item-name {
	font-size: 4.3vw;
}

.block4 .slider .owl-carousel .catalog-item-desc {
	font-size: 4vw;
	max-width: 80vw;
}

.block4 .slider .owl-carousel .catalog-item-temp {
	font-size: 3.5vw;
}

.block4 .slider .row {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.block4 .slider .row .col-md-6 {
	margin-top: 0;
	width: 70vw;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.block4 .slider .row .col-md-6:last-child {
	margin-left: 0;
	padding-left: 0;
}

.block4 .slider .row .col-md-6 .clients-image {
	margin-top: 0;
}

.block4 .slider .row .col-md-6 .client-name {
	font-size: 4.1vw;
	padding: 4vh 0px 1vh 0;
	max-width: 100px;
	text-align: center;
	margin: 0 auto;
}

.block4 .slider .row .col-md-6 .client-spec {
	font-size: 3.4vw;
}

.block4 .slider .row .col-md-6 .client-message {
	font-size: 3.8vw;
}

.block5 {
	padding: 10vh 8vw;
	border-top: 1px solid #979797;
}

.block5 .contacts .ft-bot .ft-links .ft-link {
	font-size: 3vw;
}

.block5 .mobile-phone {
	display: none;
}

.block5 .contacts {
	width: 100%;
	max-width: unset;
	margin-left: 0;
}

.block5 .contacts .place,
.block5 .contacts .inst,
.block5 .contacts .ft-desc {
	font-size: 3.8vw;
}

.block5 .contacts .ft-links {
	font-size: 3.8vw !important;
	margin-bottom: 5vh;
}

.block5 .contacts .ft-bot {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: column-reverse;
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.block5 .ft-links {
	font-size: 3.8vw !important;
	margin-bottom: 5vh;
}

.block5 .ft-bot {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: column-reverse;
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

footer.section {
	padding: 0;
}

footer .fp-tableCell {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

footer .center {
	display: none;
}

footer .left,
footer .right {
	width: unset;
	height: auto;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: #000;
	padding: 8vw;
	margin: 0;
}

footer .left a,
footer .right a {
	font-size: 3.8vw;
}

footer .left p,
footer .right p {
	color: #fff;
	font-size: 3.8vw;
}

footer .left img {
	width: 44vw;
}

footer .right a {
	text-decoration: underline;
	padding-bottom: 2px;
}

.fhs::after,
.fhs::before {
	display: none;
}

.fhs {
	width: 100%;
	height: 100%;
	margin-top: 10vh;
	margin-left: 0;
}

.fhs .close-popup {
	display: none;
}

.fhs .col-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.fhs .col-2 a {
	width: 80%;
}

.fhs .col-4 {
	text-align: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.fhs .fhs-scroll {
	height: 100%;
}

.fhs .fhs-scroll.d-flex {
	padding-top: 40px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.fhs .fhs-scroll .row {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.fhs .fhs-scroll .col-4 {
	max-width: unset;
	text-align: center;
}

.fhs .fhs-scroll .col-2 {
	max-width: unset;
}

.fhs .full-catalog-item-name {
	font-size: 4.25vw;
}

.fhs .full-catalog-item-desc {
	font-size: 2.9vw;
}

.fhs .full-catalog-item-temp {
	font-size: 2.8vw;
}

.fhs .full-catalog-item-new {
	font-size: 2.8vw;
	left: 42vw;
}

.fhs .partners-slide {
	width: 50vw;
	margin-left: 3vw;
}

.fhs .partners-slide img {
	width: 49vw;
}

.wphone {
	display: none;
}

.wmenu {
	display: none;
}

}

@-webkit-keyframes hvr-pulse {

25% {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

75% {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
}

}

@keyframes hvr-pulse {

25% {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

75% {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
}

}

@-webkit-keyframes hvr-pulse-grow {

to {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

}

@keyframes hvr-pulse-grow {

to {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

}

@-webkit-keyframes hvr-pulse-shrink {

to {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
}

}

@keyframes hvr-pulse-shrink {

to {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
}

}

@-webkit-keyframes hvr-push {

50% {
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
}

100% {
	-webkit-transform: scale(1);
	transform: scale(1);
}

}

@keyframes hvr-push {

50% {
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
}

100% {
	-webkit-transform: scale(1);
	transform: scale(1);
}

}

@-webkit-keyframes hvr-pop {

50% {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}

}

@keyframes hvr-pop {

50% {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}

}

@-webkit-keyframes hvr-bob {

0% {
	-webkit-transform: translateY(-8px);
	transform: translateY(-8px);
}

50% {
	-webkit-transform: translateY(-4px);
	transform: translateY(-4px);
}

100% {
	-webkit-transform: translateY(-8px);
	transform: translateY(-8px);
}

}

@keyframes hvr-bob {

0% {
	-webkit-transform: translateY(-8px);
	transform: translateY(-8px);
}

50% {
	-webkit-transform: translateY(-4px);
	transform: translateY(-4px);
}

100% {
	-webkit-transform: translateY(-8px);
	transform: translateY(-8px);
}

}

@-webkit-keyframes hvr-bob-float {

100% {
	-webkit-transform: translateY(-8px);
	transform: translateY(-8px);
}

}

@keyframes hvr-bob-float {

100% {
	-webkit-transform: translateY(-8px);
	transform: translateY(-8px);
}

}

@-webkit-keyframes hvr-hang {

0% {
	-webkit-transform: translateY(8px);
	transform: translateY(8px);
}

50% {
	-webkit-transform: translateY(4px);
	transform: translateY(4px);
}

100% {
	-webkit-transform: translateY(8px);
	transform: translateY(8px);
}

}

@keyframes hvr-hang {

0% {
	-webkit-transform: translateY(8px);
	transform: translateY(8px);
}

50% {
	-webkit-transform: translateY(4px);
	transform: translateY(4px);
}

100% {
	-webkit-transform: translateY(8px);
	transform: translateY(8px);
}

}

@-webkit-keyframes hvr-hang-sink {

100% {
	-webkit-transform: translateY(8px);
	transform: translateY(8px);
}

}

@keyframes hvr-hang-sink {

100% {
	-webkit-transform: translateY(8px);
	transform: translateY(8px);
}

}

@-webkit-keyframes hvr-wobble-vertical {

16.65% {
	-webkit-transform: translateY(8px);
	transform: translateY(8px);
}

33.3% {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
}

49.95% {
	-webkit-transform: translateY(4px);
	transform: translateY(4px);
}

66.6% {
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
}

83.25% {
	-webkit-transform: translateY(1px);
	transform: translateY(1px);
}

100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

}

@keyframes hvr-wobble-vertical {

16.65% {
	-webkit-transform: translateY(8px);
	transform: translateY(8px);
}

33.3% {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
}

49.95% {
	-webkit-transform: translateY(4px);
	transform: translateY(4px);
}

66.6% {
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
}

83.25% {
	-webkit-transform: translateY(1px);
	transform: translateY(1px);
}

100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

}

@-webkit-keyframes hvr-wobble-horizontal {

16.65% {
	-webkit-transform: translateX(8px);
	transform: translateX(8px);
}

33.3% {
	-webkit-transform: translateX(-6px);
	transform: translateX(-6px);
}

49.95% {
	-webkit-transform: translateX(4px);
	transform: translateX(4px);
}

66.6% {
	-webkit-transform: translateX(-2px);
	transform: translateX(-2px);
}

83.25% {
	-webkit-transform: translateX(1px);
	transform: translateX(1px);
}

100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

}

@keyframes hvr-wobble-horizontal {

16.65% {
	-webkit-transform: translateX(8px);
	transform: translateX(8px);
}

33.3% {
	-webkit-transform: translateX(-6px);
	transform: translateX(-6px);
}

49.95% {
	-webkit-transform: translateX(4px);
	transform: translateX(4px);
}

66.6% {
	-webkit-transform: translateX(-2px);
	transform: translateX(-2px);
}

83.25% {
	-webkit-transform: translateX(1px);
	transform: translateX(1px);
}

100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

}

@-webkit-keyframes hvr-wobble-to-bottom-right {

16.65% {
	-webkit-transform: translate(8px, 8px);
	transform: translate(8px, 8px);
}

33.3% {
	-webkit-transform: translate(-6px, -6px);
	transform: translate(-6px, -6px);
}

49.95% {
	-webkit-transform: translate(4px, 4px);
	transform: translate(4px, 4px);
}

66.6% {
	-webkit-transform: translate(-2px, -2px);
	transform: translate(-2px, -2px);
}

83.25% {
	-webkit-transform: translate(1px, 1px);
	transform: translate(1px, 1px);
}

100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}

}

@keyframes hvr-wobble-to-bottom-right {

16.65% {
	-webkit-transform: translate(8px, 8px);
	transform: translate(8px, 8px);
}

33.3% {
	-webkit-transform: translate(-6px, -6px);
	transform: translate(-6px, -6px);
}

49.95% {
	-webkit-transform: translate(4px, 4px);
	transform: translate(4px, 4px);
}

66.6% {
	-webkit-transform: translate(-2px, -2px);
	transform: translate(-2px, -2px);
}

83.25% {
	-webkit-transform: translate(1px, 1px);
	transform: translate(1px, 1px);
}

100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}

}

@-webkit-keyframes hvr-wobble-to-top-right {

16.65% {
	-webkit-transform: translate(8px, -8px);
	transform: translate(8px, -8px);
}

33.3% {
	-webkit-transform: translate(-6px, 6px);
	transform: translate(-6px, 6px);
}

49.95% {
	-webkit-transform: translate(4px, -4px);
	transform: translate(4px, -4px);
}

66.6% {
	-webkit-transform: translate(-2px, 2px);
	transform: translate(-2px, 2px);
}

83.25% {
	-webkit-transform: translate(1px, -1px);
	transform: translate(1px, -1px);
}

100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}

}

@keyframes hvr-wobble-to-top-right {

16.65% {
	-webkit-transform: translate(8px, -8px);
	transform: translate(8px, -8px);
}

33.3% {
	-webkit-transform: translate(-6px, 6px);
	transform: translate(-6px, 6px);
}

49.95% {
	-webkit-transform: translate(4px, -4px);
	transform: translate(4px, -4px);
}

66.6% {
	-webkit-transform: translate(-2px, 2px);
	transform: translate(-2px, 2px);
}

83.25% {
	-webkit-transform: translate(1px, -1px);
	transform: translate(1px, -1px);
}

100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}

}

@-webkit-keyframes hvr-wobble-top {

16.65% {
	-webkit-transform: skew(-12deg);
	transform: skew(-12deg);
}

33.3% {
	-webkit-transform: skew(10deg);
	transform: skew(10deg);
}

49.95% {
	-webkit-transform: skew(-6deg);
	transform: skew(-6deg);
}

66.6% {
	-webkit-transform: skew(4deg);
	transform: skew(4deg);
}

83.25% {
	-webkit-transform: skew(-2deg);
	transform: skew(-2deg);
}

100% {
	-webkit-transform: skew(0);
	transform: skew(0);
}

}

@keyframes hvr-wobble-top {

16.65% {
	-webkit-transform: skew(-12deg);
	transform: skew(-12deg);
}

33.3% {
	-webkit-transform: skew(10deg);
	transform: skew(10deg);
}

49.95% {
	-webkit-transform: skew(-6deg);
	transform: skew(-6deg);
}

66.6% {
	-webkit-transform: skew(4deg);
	transform: skew(4deg);
}

83.25% {
	-webkit-transform: skew(-2deg);
	transform: skew(-2deg);
}

100% {
	-webkit-transform: skew(0);
	transform: skew(0);
}

}

@-webkit-keyframes hvr-wobble-bottom {

16.65% {
	-webkit-transform: skew(-12deg);
	transform: skew(-12deg);
}

33.3% {
	-webkit-transform: skew(10deg);
	transform: skew(10deg);
}

49.95% {
	-webkit-transform: skew(-6deg);
	transform: skew(-6deg);
}

66.6% {
	-webkit-transform: skew(4deg);
	transform: skew(4deg);
}

83.25% {
	-webkit-transform: skew(-2deg);
	transform: skew(-2deg);
}

100% {
	-webkit-transform: skew(0);
	transform: skew(0);
}

}

@keyframes hvr-wobble-bottom {

16.65% {
	-webkit-transform: skew(-12deg);
	transform: skew(-12deg);
}

33.3% {
	-webkit-transform: skew(10deg);
	transform: skew(10deg);
}

49.95% {
	-webkit-transform: skew(-6deg);
	transform: skew(-6deg);
}

66.6% {
	-webkit-transform: skew(4deg);
	transform: skew(4deg);
}

83.25% {
	-webkit-transform: skew(-2deg);
	transform: skew(-2deg);
}

100% {
	-webkit-transform: skew(0);
	transform: skew(0);
}

}

@-webkit-keyframes hvr-wobble-skew {

16.65% {
	-webkit-transform: skew(-12deg);
	transform: skew(-12deg);
}

33.3% {
	-webkit-transform: skew(10deg);
	transform: skew(10deg);
}

49.95% {
	-webkit-transform: skew(-6deg);
	transform: skew(-6deg);
}

66.6% {
	-webkit-transform: skew(4deg);
	transform: skew(4deg);
}

83.25% {
	-webkit-transform: skew(-2deg);
	transform: skew(-2deg);
}

100% {
	-webkit-transform: skew(0);
	transform: skew(0);
}

}

@keyframes hvr-wobble-skew {

16.65% {
	-webkit-transform: skew(-12deg);
	transform: skew(-12deg);
}

33.3% {
	-webkit-transform: skew(10deg);
	transform: skew(10deg);
}

49.95% {
	-webkit-transform: skew(-6deg);
	transform: skew(-6deg);
}

66.6% {
	-webkit-transform: skew(4deg);
	transform: skew(4deg);
}

83.25% {
	-webkit-transform: skew(-2deg);
	transform: skew(-2deg);
}

100% {
	-webkit-transform: skew(0);
	transform: skew(0);
}

}

@-webkit-keyframes hvr-buzz {

50% {
	-webkit-transform: translateX(3px) rotate(2deg);
	transform: translateX(3px) rotate(2deg);
}

100% {
	-webkit-transform: translateX(-3px) rotate(-2deg);
	transform: translateX(-3px) rotate(-2deg);
}

}

@keyframes hvr-buzz {

50% {
	-webkit-transform: translateX(3px) rotate(2deg);
	transform: translateX(3px) rotate(2deg);
}

100% {
	-webkit-transform: translateX(-3px) rotate(-2deg);
	transform: translateX(-3px) rotate(-2deg);
}

}

@-webkit-keyframes hvr-buzz-out {

10% {
	-webkit-transform: translateX(3px) rotate(2deg);
	transform: translateX(3px) rotate(2deg);
}

20% {
	-webkit-transform: translateX(-3px) rotate(-2deg);
	transform: translateX(-3px) rotate(-2deg);
}

30% {
	-webkit-transform: translateX(3px) rotate(2deg);
	transform: translateX(3px) rotate(2deg);
}

40% {
	-webkit-transform: translateX(-3px) rotate(-2deg);
	transform: translateX(-3px) rotate(-2deg);
}

50% {
	-webkit-transform: translateX(2px) rotate(1deg);
	transform: translateX(2px) rotate(1deg);
}

60% {
	-webkit-transform: translateX(-2px) rotate(-1deg);
	transform: translateX(-2px) rotate(-1deg);
}

70% {
	-webkit-transform: translateX(2px) rotate(1deg);
	transform: translateX(2px) rotate(1deg);
}

80% {
	-webkit-transform: translateX(-2px) rotate(-1deg);
	transform: translateX(-2px) rotate(-1deg);
}

90% {
	-webkit-transform: translateX(1px) rotate(0);
	transform: translateX(1px) rotate(0);
}

100% {
	-webkit-transform: translateX(-1px) rotate(0);
	transform: translateX(-1px) rotate(0);
}

}

@keyframes hvr-buzz-out {

10% {
	-webkit-transform: translateX(3px) rotate(2deg);
	transform: translateX(3px) rotate(2deg);
}

20% {
	-webkit-transform: translateX(-3px) rotate(-2deg);
	transform: translateX(-3px) rotate(-2deg);
}

30% {
	-webkit-transform: translateX(3px) rotate(2deg);
	transform: translateX(3px) rotate(2deg);
}

40% {
	-webkit-transform: translateX(-3px) rotate(-2deg);
	transform: translateX(-3px) rotate(-2deg);
}

50% {
	-webkit-transform: translateX(2px) rotate(1deg);
	transform: translateX(2px) rotate(1deg);
}

60% {
	-webkit-transform: translateX(-2px) rotate(-1deg);
	transform: translateX(-2px) rotate(-1deg);
}

70% {
	-webkit-transform: translateX(2px) rotate(1deg);
	transform: translateX(2px) rotate(1deg);
}

80% {
	-webkit-transform: translateX(-2px) rotate(-1deg);
	transform: translateX(-2px) rotate(-1deg);
}

90% {
	-webkit-transform: translateX(1px) rotate(0);
	transform: translateX(1px) rotate(0);
}

100% {
	-webkit-transform: translateX(-1px) rotate(0);
	transform: translateX(-1px) rotate(0);
}

}

@-webkit-keyframes hvr-back-pulse {

50% {
	background-color: rgba(32, 152, 209, 0.75);
}

}

@keyframes hvr-back-pulse {

50% {
	background-color: rgba(32, 152, 209, 0.75);
}

}

@-webkit-keyframes hvr-ripple-out {

100% {
	top: -12px;
	right: -12px;
	bottom: -12px;
	left: -12px;
	opacity: 0;
}

}

@keyframes hvr-ripple-out {

100% {
	top: -12px;
	right: -12px;
	bottom: -12px;
	left: -12px;
	opacity: 0;
}

}

@-webkit-keyframes hvr-ripple-in {

100% {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 1;
}

}

@keyframes hvr-ripple-in {

100% {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 1;
}

}

@-webkit-keyframes hvr-icon-down {

0%,50%,100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

25%,75% {
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
}

}

@keyframes hvr-icon-down {

0%,50%,100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

25%,75% {
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
}

}

@-webkit-keyframes hvr-icon-up {

0%,50%,100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

25%,75% {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
}

}

@keyframes hvr-icon-up {

0%,50%,100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

25%,75% {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
}

}

@-webkit-keyframes hvr-icon-drop {

0% {
	opacity: 0;
}

50% {
	opacity: 0;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
}

51%,100% {
	opacity: 1;
}

}

@keyframes hvr-icon-drop {

0% {
	opacity: 0;
}

50% {
	opacity: 0;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
}

51%,100% {
	opacity: 1;
}

}

@-webkit-keyframes hvr-icon-float-away {

0% {
	opacity: 1;
}

100% {
	opacity: 0;
	-webkit-transform: translateY(-1em);
	transform: translateY(-1em);
}

}

@keyframes hvr-icon-float-away {

0% {
	opacity: 1;
}

100% {
	opacity: 0;
	-webkit-transform: translateY(-1em);
	transform: translateY(-1em);
}

}

@-webkit-keyframes hvr-icon-sink-away {

0% {
	opacity: 1;
}

100% {
	opacity: 0;
	-webkit-transform: translateY(1em);
	transform: translateY(1em);
}

}

@keyframes hvr-icon-sink-away {

0% {
	opacity: 1;
}

100% {
	opacity: 0;
	-webkit-transform: translateY(1em);
	transform: translateY(1em);
}

}

@-webkit-keyframes hvr-icon-pulse {

25% {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

75% {
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
}

}

@keyframes hvr-icon-pulse {

25% {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

75% {
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
}

}

@-webkit-keyframes hvr-icon-pulse-grow {

to {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

}

@keyframes hvr-icon-pulse-grow {

to {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

}

@-webkit-keyframes hvr-icon-pulse-shrink {

to {
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
}

}

@keyframes hvr-icon-pulse-shrink {

to {
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
}

}

@-webkit-keyframes hvr-icon-push {

50% {
	-webkit-transform: scale(0.5);
	transform: scale(0.5);
}

}

@keyframes hvr-icon-push {

50% {
	-webkit-transform: scale(0.5);
	transform: scale(0.5);
}

}

@-webkit-keyframes hvr-icon-pop {

50% {
	-webkit-transform: scale(1.5);
	transform: scale(1.5);
}

}

@keyframes hvr-icon-pop {

50% {
	-webkit-transform: scale(1.5);
	transform: scale(1.5);
}

}

@-webkit-keyframes hvr-icon-bob {

0% {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
}

50% {
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
}

100% {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
}

}

@keyframes hvr-icon-bob {

0% {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
}

50% {
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
}

100% {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
}

}

@-webkit-keyframes hvr-icon-bob-float {

100% {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
}

}

@keyframes hvr-icon-bob-float {

100% {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
}

}

@-webkit-keyframes hvr-icon-hang {

0% {
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
}

50% {
	-webkit-transform: translateY(2px);
	transform: translateY(2px);
}

100% {
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
}

}

@keyframes hvr-icon-hang {

0% {
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
}

50% {
	-webkit-transform: translateY(2px);
	transform: translateY(2px);
}

100% {
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
}

}

@-webkit-keyframes hvr-icon-hang-sink {

100% {
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
}

}

@keyframes hvr-icon-hang-sink {

100% {
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
}

}

@-webkit-keyframes hvr-icon-wobble-horizontal {

16.65% {
	-webkit-transform: translateX(6px);
	transform: translateX(6px);
}

33.3% {
	-webkit-transform: translateX(-5px);
	transform: translateX(-5px);
}

49.95% {
	-webkit-transform: translateX(4px);
	transform: translateX(4px);
}

66.6% {
	-webkit-transform: translateX(-2px);
	transform: translateX(-2px);
}

83.25% {
	-webkit-transform: translateX(1px);
	transform: translateX(1px);
}

100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

}

@keyframes hvr-icon-wobble-horizontal {

16.65% {
	-webkit-transform: translateX(6px);
	transform: translateX(6px);
}

33.3% {
	-webkit-transform: translateX(-5px);
	transform: translateX(-5px);
}

49.95% {
	-webkit-transform: translateX(4px);
	transform: translateX(4px);
}

66.6% {
	-webkit-transform: translateX(-2px);
	transform: translateX(-2px);
}

83.25% {
	-webkit-transform: translateX(1px);
	transform: translateX(1px);
}

100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

}

@-webkit-keyframes hvr-icon-wobble-vertical {

16.65% {
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
}

33.3% {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
}

49.95% {
	-webkit-transform: translateY(4px);
	transform: translateY(4px);
}

66.6% {
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
}

83.25% {
	-webkit-transform: translateY(1px);
	transform: translateY(1px);
}

100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

}

@keyframes hvr-icon-wobble-vertical {

16.65% {
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
}

33.3% {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
}

49.95% {
	-webkit-transform: translateY(4px);
	transform: translateY(4px);
}

66.6% {
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
}

83.25% {
	-webkit-transform: translateY(1px);
	transform: translateY(1px);
}

100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

}

@-webkit-keyframes hvr-icon-buzz {

50% {
	-webkit-transform: translateX(3px) rotate(2deg);
	transform: translateX(3px) rotate(2deg);
}

100% {
	-webkit-transform: translateX(-3px) rotate(-2deg);
	transform: translateX(-3px) rotate(-2deg);
}

}

@keyframes hvr-icon-buzz {

50% {
	-webkit-transform: translateX(3px) rotate(2deg);
	transform: translateX(3px) rotate(2deg);
}

100% {
	-webkit-transform: translateX(-3px) rotate(-2deg);
	transform: translateX(-3px) rotate(-2deg);
}

}

@-webkit-keyframes hvr-icon-buzz-out {

10% {
	-webkit-transform: translateX(3px) rotate(2deg);
	transform: translateX(3px) rotate(2deg);
}

20% {
	-webkit-transform: translateX(-3px) rotate(-2deg);
	transform: translateX(-3px) rotate(-2deg);
}

30% {
	-webkit-transform: translateX(3px) rotate(2deg);
	transform: translateX(3px) rotate(2deg);
}

40% {
	-webkit-transform: translateX(-3px) rotate(-2deg);
	transform: translateX(-3px) rotate(-2deg);
}

50% {
	-webkit-transform: translateX(2px) rotate(1deg);
	transform: translateX(2px) rotate(1deg);
}

60% {
	-webkit-transform: translateX(-2px) rotate(-1deg);
	transform: translateX(-2px) rotate(-1deg);
}

70% {
	-webkit-transform: translateX(2px) rotate(1deg);
	transform: translateX(2px) rotate(1deg);
}

80% {
	-webkit-transform: translateX(-2px) rotate(-1deg);
	transform: translateX(-2px) rotate(-1deg);
}

90% {
	-webkit-transform: translateX(1px) rotate(0);
	transform: translateX(1px) rotate(0);
}

100% {
	-webkit-transform: translateX(-1px) rotate(0);
	transform: translateX(-1px) rotate(0);
}

}

@keyframes hvr-icon-buzz-out {

10% {
	-webkit-transform: translateX(3px) rotate(2deg);
	transform: translateX(3px) rotate(2deg);
}

20% {
	-webkit-transform: translateX(-3px) rotate(-2deg);
	transform: translateX(-3px) rotate(-2deg);
}

30% {
	-webkit-transform: translateX(3px) rotate(2deg);
	transform: translateX(3px) rotate(2deg);
}

40% {
	-webkit-transform: translateX(-3px) rotate(-2deg);
	transform: translateX(-3px) rotate(-2deg);
}

50% {
	-webkit-transform: translateX(2px) rotate(1deg);
	transform: translateX(2px) rotate(1deg);
}

60% {
	-webkit-transform: translateX(-2px) rotate(-1deg);
	transform: translateX(-2px) rotate(-1deg);
}

70% {
	-webkit-transform: translateX(2px) rotate(1deg);
	transform: translateX(2px) rotate(1deg);
}

80% {
	-webkit-transform: translateX(-2px) rotate(-1deg);
	transform: translateX(-2px) rotate(-1deg);
}

90% {
	-webkit-transform: translateX(1px) rotate(0);
	transform: translateX(1px) rotate(0);
}

100% {
	-webkit-transform: translateX(-1px) rotate(0);
	transform: translateX(-1px) rotate(0);
}

}

@-webkit-keyframes bg {

0% {
	background-position-x: -200px;
}

50% {
	background-position-x: 0px;
}

100% {
	background-position-x: -200px;
}

}

@keyframes bg {

0% {
	background-position-x: -200px;
}

50% {
	background-position-x: 0px;
}

100% {
	background-position-x: -200px;
}

}

