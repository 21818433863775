@import "vars";


@media screen and (max-width: 600px) {
  .mobile-dn{
    display: none;
  }
  .desc-dn{
    display: block;
  }
  .popup{
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
    display: none;
    .popup-logo{
      width: 30vw;
      position: absolute;
      left: 8vw ;
      top: 35px;
    }
    .checks ul>li{
      font-size: 3vw  ;
      list-style-type: none;
    }
    .checks{
      label{
        position: relative;
        margin-left: -10px;

      }
    }
    h3{
      font-size: 5.5vw;

      margin-bottom:10px;
    }
    p{
      display: none;
    }
    input{
      font-size: 3.1vw;
      padding-bottom: 10px;

    }
    .popup-inner{
      height: 100%;
      padding: 0 8vw;
    }
    .row{
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .col{
      width: 100%;
        .button-main-bordered{
          align-self: center;
        }
    }}
    .close-popup{
      right: 7vw;
      top: 8vw;
      img{
        padding: 0;

      }
      span{
        display: none;
      }
      img{
        width: 10vw;

      }
    }
  }

  //global
  h1{
    font-size: 6.6vw;
    letter-spacing: 0;
    br{
      display: none;
    }
  }
  h2{
    font-size: 5.5vw;
    letter-spacing: 0;
  }

  h4{
    font-size: 3.8vw;
  }
  //buttons
  .button-main-bordered, .button-main{
    width: 48vw;
    height: 9.5vh;
    font-size: 3.1vw;
  }
  .fixed-menu{
    left: 8vw;
    top: 8vw;
  display: none;
  }
  .mobile-menu{
    position: fixed;
    right: 8vw;
    z-index: 99;
    display: flex;
    top: 12vw;
    transition:.3s ease-in-out;

    img{
      width: 10vw;
  }
  }
  .mobile-menu-opened{
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #151515;
    z-index: 990;
    a{
      color: #fff;
      font-family: $default-font-bold;


    }
    .header{
      display: flex;
      width: 100vw;
      justify-content: space-between;
      padding-top: 3vw;
      padding-bottom: 2vw;
      border-bottom: 1px solid #fff;
      .logo{
        width: 30vw;
        padding-left: 8vw;
      }
      .close{
        width: 10vw;
        margin-top: 2vw;
        padding-right: 8vw;
      }
    }
    .main{
      padding-left: 8vw;
      padding-top: 20vw;
      ul{
        padding: 0;
      }
      li{
        list-style-type: none;
        line-height: 10vw;
      }
      .button-main{
        margin-top: 20px;
      }
    }
    .footer{
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      width: 86vw;
      margin: 0 auto;
      padding-left: 8vw;
      padding-bottom: 10vw;
      img{
        width: 6vw;
        vertical-align: middle;
        padding-left: 2vw;
      }
      a{
        font-family: $default-font;
        color: #dbdbdb;
      }

    }
  }
  .menu{
    img{
    padding-left: 3vw;
    }
  }
  .fixed-logo{
    transition:.3s ease-in-out;
    left: 8vw;
    top: 8vw;
    z-index: 99;
    img {
      width: 43vw;
    }
    .mobile-logo{
      width: 30vw;
    }
  }
  .section{
    padding:0 8vw;
  }
  .block1{
    padding-left: 8vw;
    background-image: url("../img/mobile-bg.jpg");
    .buttons{
      flex-direction: column;
      >*{
        margin-bottom: 1vh;
      }
    }
  }
  .block2{
    background-image: none;
    padding-left: 0;
    .block-image{
      display: none;
    }
    .block2-text{
      font-family: $default-font;
      font-size: 3.8vw;
      text-align: left;
      margin-top: -10vh;
      margin-bottom: 8vh;
      padding-left: 8vw;
      padding-right: 8vw;
    }
    .button-main-bordered{
      bottom: 10vh;
    }
    .slider {
      width: 100%;
      left: 0;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      position: relative;
      height: auto;
      &:before{
        display: none;
      }
      &:after{
        display: none;
      }
      .catalog-arrows-all {
        display: flex;
        width: 84vw;
        margin: 0 auto;
        justify-content: space-between;
        top: 25%;
        left: unset;
      .catalog-slider-arrow .bordered-arrow {
        z-index: 22;
        img {
          width: 10vw;
        }
      }
    }
        .owl-carousel{
        left: 8vw;
        width: 100%;
        position: unset;
        margin-bottom: -5vh;
        .catalog-slide{
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding-left: 0;
          img{
            margin-left: 0;
            max-height: 48vw;
          }
        }
        .catalog-item-name{
          font-size: 4.3vw;
        }
        .catalog-item-desc{
          font-size: 4vw;
          max-width: 80vw;
        }
        .catalog-item-temp{
          font-size: 3.5vw;
          margin-top: 10px;
        }
          .catalog-item-new{
            font-size: 4.5vw;
            top: 165px;
          }
      }
    }
   .button-main-bordered{
    left: 25vw;
     bottom: 6vh;
  }
  }
  .block3{
    .button-main-bordered {
      display: none;
    }
    h4{
      font-family: $default-font-bold;}
  }
  .block4{
    .block-image{
      display: none;
    }

    .owl-carousel .owl-item img{
      width:150px;
    }
    .slider {
      padding-top: 10vh;
      width: 100%;
      left: 0;
      display: flex;
      FLEX-DIRECTION: column;
      align-items: center;
      position: relative;
      height: auto;
      &:before{
        display: none;
      }
      &:after{
        display: none;
      }
      .button-main-bordered {
        position: relative;
        right: 0;
        left: 0;
        margin-top: 20vh;
      }
      .catalog-arrows-all {
        display: flex;
        width: 84vw;
        margin: 0 auto;
        justify-content: space-between;
        top: 28%;
        left: unset;
        z-index: 2;
        .catalog-slider-arrow .bordered-arrow {
          z-index: 999;
          img {
            width: 10vw;
          }
        }
      }
      .owl-carousel{
        left: 8vw;
        width: 100%;
        position: unset;
        margin-bottom: -5vh;

        .catalog-slide{
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding-left: 0;
          img{
            margin-left: 0;
            max-height: 55vw;

          }
          .catalog-item-new{
            font-size: 5.8vw;
            top: 278px;
          }
        }
        .catalog-item-name{
          font-size: 4.3vw;
        }
        .catalog-item-desc{
          font-size: 4vw;
          max-width: 80vw;
        }
        .catalog-item-temp{
          font-size: 3.5vw;
        }
      }
      .row {
        flex-direction: column;
        align-items: center;
        text-align: center;
        .col-md-6 {
          margin-top: 0;
          width: 70vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &:last-child{
            margin-left: 0;
            padding-left: 0;
          }
          .clients-image {
            margin-top: 0;
          }
          .client-name{
            font-size: 4.1vw;
            padding: 4vh 0px 1vh 0;
            max-width: 100px;
            text-align: center;
            margin: 0 auto;
          }
          .client-spec{
            font-size: 3.4vw;
          }
          .client-message{
            font-size: 3.8vw;

          }
        }
      }
    }
  }
  .block5{
    padding: 10vh 8vw ;

    border-top: 1px solid #979797;
    .contacts .ft-bot .ft-links .ft-link{
      font-size: 3vw;
    }
    .mobile-phone{
      display: none;
    }
    .contacts{
      width: 100%;
      max-width: unset;
      margin-left: 0;
      .place,.inst,.ft-desc{
        font-size: 3.8vw;
      }
      .ft-links{
        font-size: 3.8vw!important;
        margin-bottom: 5vh;
      }
      .ft-bot{
        flex-direction: column-reverse;
        align-items: flex-start;

      }
    }
    .ft-links{
      font-size: 3.8vw!important;
      margin-bottom: 5vh;
    }
    .ft-bot{
      flex-direction: column-reverse;
      align-items: flex-start;

    }
  }
  footer{
    &.section{
      padding: 0;
    }
    .fp-tableCell{
    flex-direction: column;
    }
    .center{
      display: none;
    }
    .left,.right{
      width: unset;
      height: auto;
      align-items: flex-start;
      background-color: #000;
      padding:8vw;
      margin: 0;
      a{
        font-size: 3.8vw;

      }
      p{
        color: #fff;
        font-size: 3.8vw;

      }
    }
    .left img{
      width: 44vw;
    }
    .right a{
      text-decoration: underline;
      padding-bottom: 2px;
    }
  }
  .fhs::after, .fhs::before {
    display: none;
  }
  .fhs{
    width: 100%;
    height: 100%;
    margin-top: 10vh;
    margin-left: 0;
    .close-popup{
      display: none;
    }
    .col-2{
      display: flex;
      justify-content: center;
      a{
        width: 80%;
      }
    }
    .col-4{
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .fhs-scroll{
      &.d-flex{
        padding-top: 40px;
        justify-content: center;
        align-items: center;
      }
      height: 100%;
      .row{
        flex-direction: column;
      }
      .col-4{
        max-width: unset;
        text-align: center;
      }
      .col-2{
        max-width: unset;
      }
    }
    .full-catalog-item-name {
      font-size: 4.25vw;}
    .full-catalog-item-desc {
      font-size: 2.9vw;}
    .full-catalog-item-temp {
      font-size: 2.8vw;}
    .full-catalog-item-new{
      font-size: 2.8vw;
      left: 42vw;
    }

    .partners-slide{
      width: 50vw;
      margin-left: 3vw;
      img{
        width: 49vw;

      }
    }
  }
 .wphone{
   display: none;
 }
  .wmenu{
    display: none;
  }
}