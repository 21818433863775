
@import "vars";
@import "fonts";
@import "libs";
@font-face {
	font-family: 'Supply';
	src: url(../fonts/supple/Supply-Regular.otf);
}
.desc-dn{
	display: none;
}
.mobile-dn{
	display: block;
}
//buttons
.button-main{
	text-decoration: none;
	width: 12vw;
	height: 7.5vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: .7vw;
	color: #000000;
	letter-spacing: 0.07px;
	text-transform: uppercase;
	font-family: $default-font;
	border-radius: 2px;
	background-color: #eeeeee;
	transition:.3s ease-in-out;
	cursor: pointer;
	border: 0;
	&:hover{
		color: #404040;
	}
}
.button-main-bordered {
	text-decoration: none;
	cursor: pointer;
	width: 12vw;
	height: 7.5vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: .7vw	;
	color: #ffffff;;
	letter-spacing: 0.07px;
	text-transform: uppercase;
	font-family: $default-font;
	border-radius: 2px;
	background-color: transparent;
	border: 1.4px #ffffff solid;
	transition:.3s ease-in-out;
}
.button-main-bordered:hover{
	color:#000;
}
.button-main-bordered:hover:before{
	height:100%;

}
.button-main-bordered.black{
	color: #000;
	border-color: #000;
	&:before{
		background: #000;
	}
	&:hover{
		color: #fff;
	}
}

input[type="checkbox"]:checked,
input[type="checkbox"]:not(:checked),
input[type="radio"]:checked,
input[type="radio"]:not(:checked)
{
	position: absolute;
	left: -9999px;
}

input[type="checkbox"]:checked + label,
input[type="checkbox"]:not(:checked) + label,
input[type="radio"]:checked + label,
input[type="radio"]:not(:checked) + label {
	display: inline-block;
	position: relative;
	padding-left: 28px;
	line-height: 20px;
	cursor: pointer;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before,
input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 0px;
	width: 15px;
	height: 15px;
	border: 1px solid #dddddd;
	background-color: #ffffff;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
	border-radius: 2px;
}

input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
	border-radius: 100%;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after,
input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
	content: "";
	position: absolute;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
	left: 3px;
	top: 2px;
	width: 10px;
	height: 5px;
	border-radius: 1px;
	border-left: 4px solid #000;
	border-bottom: 4px solid #000;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
	left: 5px;
	top: 5px;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: #000;
}
input[type="checkbox"]:not(:checked) + label:after,
input[type="radio"]:not(:checked) + label:after {
	opacity: 0;
}

input[type="checkbox"]:checked + label:after,
input[type="radio"]:checked + label:after {
	opacity: 1;
}
//typography
a{
	text-decoration: none;
}
h1{
	font-size: 2.5vw;
	font-family: $default-font-bold;
	text-transform: uppercase;
	letter-spacing: 3px;
	line-height: 1.3em;
}
h2{
  font-size: 2vw;
  font-family: $default-font-bold;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1.6em;
}
h3{
	font-family: $default-font;
	font-size: 1.45vw;
	letter-spacing: .1vw;
	color: #000;
	font-weight: 100;
  margin: 0;
}
h4{
	font-size:1vw;
	font-family:$default-font;
	text-transform:uppercase;
	letter-spacing:1px;
	font-weight: 100;
}
p{
	color: #363636;
}
//loader
.loader {
	height:100vh;
	width:100vw;
	position:fixed;
	top:0;
	left:0;
	background:#fff;
	z-index:1000;
	display: flex;
	justify-content: center;
	align-items: center;

	.logo-text {
		font-family: 'ClearSans-Bold', serif;
		text-transform: uppercase;
		font-size: 6.3vw;
		background-size: 100vw !important;
		background: url("../img/loader_image.jpg") center left no-repeat #171717;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		fill: transparent;
		display: inline;
		animation: bg 25s linear;
	}

	@keyframes bg {
		0% {
			background-position-x: -200px;
		}
		50% {
			background-position-x: 0px;
		}
		100% {
			background-position-x: -200px;
		}
	}
	.logo-line {
		width: 25vw;
		height: 3px;
		background: #e3e3e3;
		margin: 4vh auto;
		position: relative;
	}
	.logo-line div {
		width: 0;
		float: left;
		height: 3px;
		background: #171717;
		transition: .7s;
	}
}
//fixed menu
.fixed-logo{
	cursor: pointer;
	position: fixed;
	top: 35px;
	left: 35px;
	z-index: 9;
	.logo-black{
		display: none;
	}
	img{
		width: 10vw;
	}
}
.fixed-menu {
	border-left:1px solid rgba(255,255,255,.3);
	position:fixed;
	top:0;
	right:0;
	height:100vh;
	z-index:100;
	width:18vw;

	transition:.3s ease-in-out;
	display: flex;
	align-items: center;
	flex-direction: column;
	.fixed-button{
		margin-top: 35px;
		z-index: 99;
	}
	ul{
		list-style:none;
		padding:0;
		margin:0;
		font-size:.9vw;
		letter-spacing:.2px;
        li.active a{
          color: #fff;
        }
		li {
			padding:1.4vh 0; 
			a{
				color:#ccc;
				text-decoration:none;
                font-family: 'ClearSans-Bold', serif;
				text-transform:uppercase;
				position:relative;
				display:inline-block;
				padding:1px 5px;
				z-index:10;
				transition:.3s ease-in-out;
			}
		}
	}
	.menu{
		position: absolute;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
			align-items: center;
	}

	.fixed-instagram{
		position: absolute;
		bottom:55px;
		display: flex;
		flex-direction: column;
		.tel{
			padding-bottom: 15px;
		}
		.inst-black{
			display: none;
		}
		a{
			color: #fff;
            font-family: 'ClearSans-Bold', serif;
          font-size: .9vw;
			font-weight: 900;


        }
		img{
			max-width: 82px;
		}
	}
}
.fixed-menu.black-menu{
	border-left:1px solid rgba(0,0,0,.6);
	.fixed-instagram a{
		color: #000;
	}
	a{
		color:#ccc;

	}
	.active a{
		color: #000;
	}
}
//slider
.slider{
	position: absolute;
	top: 0;

	height: 100vh;
   .button-main-bordered{
     position: absolute;
     top: 0;
     right: 35px;
     margin-top: 35px;
   }
	&:before{
		content:'';
		display:block;
		width:64px;
		height:60%;
		position:absolute;
		background:url("../img/slider-l.png") center right;
		z-index:100;
		left:0;
		top: 12vw;
	}
	&:after{
		content:'';
		display:block;
		width:64px;
		height:60%;
		position:absolute;
		background:url("../img/slider.png") center right;
		z-index:100;
		right:0;
		top: 12vw;
	}
	.catalog-arrows-all{
		position: absolute;
		top: 19vh;
		left: 4.515vw;
		display: flex;
		font-family: $default-font;
		.catalog-slider-arrow{
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: .8vw;
			letter-spacing: .2px;
			text-transform: uppercase;
			span{
				margin-right:.485vw;
			}
			.bordered-arrow img{
              width: 3.5vw;
				cursor: pointer;
			}
		}

	}
	.owl-carousel{
		position: absolute;
		top: 27.5vh;
		width: 47vw;
		font-family: $default-font;
		h3{
			margin-top: 15px;
		}
		.catalog-slide {
			padding-left: 6vw;
			.catalog-item-new {
				position: absolute;
				font-family: $default-font-bold;
				z-index: 100;
				color: #29bc54;
				font-size: .8vw;
				top: 266px;
			}
          .catalog-item-desc {
            color: #363636;
            font-size: .88vw;
            letter-spacing: .05vw;
            margin-bottom: .5rem;
          }
          .catalog-item-temp {
            font-family: Supply;
            font-size: .80vw;
            color: #818181;
            margin-top: 25px;
			  letter-spacing: 0.3vw;
          }
		}
	}
	.arrow-left {
		transform: rotate(90deg);
	}
	.arrow-right {
		transform: rotate(-90deg);
	}
	.catalog-slide img{
		max-height: 265px;
		width: auto;
		margin-left: -13%;

	}
}
//popup
.popup{
	position: fixed;
	background-color: #fff;
	border-radius: 3px;
	height: 70vh;
	width: 57vw;
	top: 17.5vh;
	left:  11vw;
	color:#000;
	font-family: $default-font;
	transition:.5s ease-in;
	opacity: 0;
	box-shadow:0px 0px 5px rgba(0,0,0,.2);
	z-index: -1;
	&.active {
		z-index: 999;
		display: block!important;
		opacity: 1;
	}

	h4{
		text-transform: none;
	}
	p{
		font-size: .9vw;
	}
	.close-popup {
		position: absolute;
		right: 3.7vw;
		top: 2.7vw	;
		a{
			color:#000;
			font-size: .8vw;
			text-transform: uppercase;

		}
		img {
			width: 3vw;
			vertical-align: middle;
			padding-right: 10px;
		}
	}
	.popup-inner{
		padding: 3.7vw;
		height: 44.5vh;
		padding-top: 6vw;
	}
	.checks {
		margin-bottom: -6.2vw;
		margin-left: -3.5vw;
		ul > li {
			position: relative;
			margin-top: 5px;
			font-size: .7vw;
			list-style-type: none;
		}
		label {
			position: absolute;
			margin-left: 2px;
		}
	}
	input{
		border-radius: 0;
		border: 0;
		border-bottom: 1px solid #C1C1C1;
		margin-bottom: 15px;
		width: 100%;
		margin-top: 10px;
		font-size: 10px;
		padding-bottom: 5px;
	}
	.row{
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;

		.col{
			display: flex;
			flex-direction: column;
			width: 48%;
	.button-main-bordered{
		align-self: flex-end;
		margin-top: 30px;
		margin-bottom: -30px;
	}
		}
	}
}
//other
.section{
	padding-right: 18vw;
}
.block1{
	background-image: url("../img/microgreen-top.jpg");
	background-size: cover;
	color: #fff;
	padding-left: 13vw;
	.buttons{
		display: flex;
		>*{
			margin-right: 5px;
		}
	}
}
.block2{
	background-size: 76vh;
	background-image: url(../img/catalog.png);
	background-repeat: no-repeat;
	background-position-x: 68vw;

	.block-image{
		width: 32.2vw;
		background-image: url("../img/block-2-bg.jpg");
		height: 100vh;
	}
  .slider{
    left: 33vw;
    width: 47vw;
	  top: -5vw;
	.catalog-arrows-all{
		left: 6vw;
	}
  }
	.button-main-bordered {
		color: #000;
		border-color: #000;
		position: absolute;
		bottom: 5vw;
		left: 39vw;
	}
}
.block3{
  background: url(../img/grass.jpg) center no-repeat;
  background-size: cover;
  background-position-x: center;
  color: #fff;
  padding: 0 18vw 0 5vw;

}
.block4{
	//margin-bottom: -5vh;

    .block-image{
      background: url("../img/block-4-image.jpg") no-repeat;
      background-position-x: center;
      width: 30vw;
      position: absolute;
      right: 0;
      height: 100vh;
      top: 0;

    }
  .owl-carousel{
    top: 16vh;
    width: 60vw;
  }
  .slider{
    left: 6vw;
    width: 62vw;
    &:before{
      display: none;
    }
	  .button-main-bordered{
		  bottom: 16vh;
		  left: 26.6vw;
		  top: unset;
	  }
    .client-name {
      font-size: 1.25vw;
      letter-spacing: .2vw;
      color: #000;
      text-transform: uppercase;
    }
    .client-spec {
      padding-bottom: 3vh;
      padding-top: 1vh;
      font-size: 1vw;
      text-transform: uppercase;
		width: 82%;
    }
    .client-message {
      font-size: 1vw;
      letter-spacing: .02vw;
      line-height: 3.2vh;
		color: #363636;
    }
    & .catalog-arrows-all{
      top: 16vh;
      left: 26.6vw;
      z-index: 99;
    }
    .row{
      display: flex;


      .col-md-6{
        width: 50%;
        padding: 0 0 2vw 2vw;
        margin-top: 10vh;
        &:last-child{
          margin-left: 3vw;
        }
		  &:first-child{
			  width: 33%;
		  }
      }
    }
  }
}
.block5{

	.fp-tableCell{
		//padding:10vh 0;
	}
  .mobile-phone img{
    width: 15vw;
  }
  .fp-tableCell {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contacts{
    max-width: 25vw;
    margin-left: 10vw;
	  .place,.inst{
		  font-family: $default-font-bold!important;
	  }
    img{
      height: 14px;
      vertical-align: middle;
      padding-right: .5vw;
    }
    .ft-desc {
      font-size: 1vw;
      letter-spacing: .060vw;
      font-family: $default-font;
		color: #363636;
    }
    .place,.inst{
      font-size: .865vw;
      letter-spacing: .2px;
      padding-bottom: 2vh;
      font-family: $default-font;
    }
    .ft-bot{
      display: flex;
      align-items: center;
      padding-top: 30px;
      .ft-links{
        padding-left: 2vw;
        font-size: .7vw;
        letter-spacing: .060vw;
        font-family: $default-font;
		  text-transform: uppercase;
		  .ft-link{
			  padding: 5px 0;
		  }
        p{
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
.ft-links{
	font-size: .7vw;
	letter-spacing: .060vw;
	font-family: $default-font;
	text-transform: uppercase;
	.ft-link{
		padding: 5px 0;
	}
	p{
		margin: 0;
		padding: 0;
		font-size: .7vw;

	}
}
footer{
	&.section{
		padding-right: 0;
	}
	font-family: $default-font;
	color: #fff;
	background-color: #1c1c1c;
	font-size: 1vw;
	a{
		opacity: .5;
		color: #fff;
		padding-bottom: 1.5vh;
	}
	span{
		opacity: .5;
	}
	p{
		color: #fff;
	}
	.fp-tableCell {
		display: flex;
		justify-content: space-between;
		width: 100%;

	}
	.fp-auto-height{

	}
	.left{
		height: 25vh;
		width: 30vw;
		background: #000;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding-top: 5vw;
		padding-bottom: 5vw;

		img{
			width: 15vw;

		}
		p{
			opacity: .5;
		}
	}
	.center{
		display: flex;
		padding-top: 5vw;
		justify-content: space-between;
		width: 25vw;

		>*{
			display: flex;
			flex-direction: column;
		}
		h4{
			padding-top: 0;
			margin-top: 0;
			text-transform: none;
		}
	}
	.right{
		width: 30vw;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;
		padding-right: 5vw;
		padding-bottom: 5vw;
		padding-top: 5vw;
		margin-left: -5vw;
	}
}
.mobile-menu-back{
	display: none;
	opacity: 0.8;
	background-color: #151515;
	height: 21vw;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 30;
	left: 0;
}


//catalog
.full-catalog-item-name {
	font-size: 1.25vw;
	letter-spacing: .12vw;
	color:#000;
	margin-top:25px;

	text-transform:uppercase;
	font-family: $default-font;
}
.full-catalog-item-desc {
	 font-size: .9vw;
	 letter-spacing: .05vw;
	color: #363636;
	width: 69%;
	font-family: $default-font;

 }
.full-catalog-item-temp {
	  font-family:'Supply', sans-serif;
	  font-size: .8vw;
	  color:#818181;
	  margin-top:15px;
  }
.full-catalog-item-new {

	position: absolute;

	top: 10px;
	font-family: $default-font-bold;
	z-index: 10;
	color: #29bc54;
	font-size: .8vw;

}
.col-2{
	position: relative;
	max-width: 17%;
	width: 100%;

	img{
		width: 100%;
	}
}
.col-4{
	max-width: 33%;
	width: 100%;
	position: relative;
}
.fhs{
	.close-popup{
		position: absolute;
		top: 35px;
		right: 22vw;
		z-index: 999;
		a{
			color: #000;
			font-size: .8vw;
			text-transform: uppercase;
			font-family: $default-font;
		}
		img{
			width: 3vw;
			vertical-align: middle;
			padding-left: 10px;

		}
	}
	width: 70vw;
	height: 80vh;
	margin-top: 20vh;
	margin-left: 8vw;
	&:before {
		content: '';
		display: block;
		width: 80vw;
		height: 62px;
		position: absolute;
		top: 20vh;
		left: 0;
		z-index: 100;
		background: url(../img/grad-bottom.png) center top repeat-x;
	}
	&:after {
		content: '';
		display: block;
		width: 80vw;
		height: 62px;
		position: absolute;
		bottom: -2px;
		left: 0;
		z-index: 100;
		background: url(../img/grad-top.png) center top repeat-x;
	}
	.fhs-scroll{
		overflow-x: hidden !important;
		position: relative;
		height: 80vh;
	}
	.row{
		margin-top: 5vh;
		display: flex;
	}
}
//partners

.partners-slide{
	width: 25vw;
	margin-left: 3vw;
	img{
		width: 16vw;

	}
}
.d-flex{
	flex-wrap: wrap;
	display: flex;
}
form{
	display: flex;
	flex-direction: column;
}
@import "media"; // Always at the end
